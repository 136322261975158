import { gsap, ScrollTrigger } from "gsap/all";

export function numberStatistics() {
    if(document.querySelectorAll('.counter-title').length > 0) {
        document.querySelectorAll('.counter-title').forEach(el=> {
            const dataValue = el.getAttribute('data-value');
            const dataDuration = el.getAttribute('data-duration');
    
            ScrollTrigger.create({
                trigger: el,
                start: 'top bottom',
                onEnter: () => {
                    let Cont = { val: 0 },
                    NewVal = dataValue;
    
                    gsap.to(Cont, Number(dataDuration), {
                    val: NewVal,
                    roundProps: "val",
                    onUpdate: function () {
                        el.querySelector('span').innerHTML = Cont.val;
                    }
                    });
                },
            });
        });
    }
}