export function addCardServices(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const image = elem.main_photo ? elem.main_photo : '../static/assets/images/stub.png';
        const slug = `${elem.url}`
    
        const link = document.createElement('a');
        link.href = `${slug}`;

        link.classList.add('service-card-small');
        link.innerHTML = `
            <div class="service-card-small__image">
                <img src="${image}">
            </div>
            <div class="service-card-small__info-block">
                <div class="service-card-small__info-block__title">
                    ${elem.name}
                </div>
                <div class="service-card-small__info-block__sub-title">
                    от ${elem.price} ₽
                </div>
            </div>
        `;

    fragment.appendChild(link);
    })

    section.appendChild(fragment);
}