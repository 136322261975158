import { gsap } from "gsap/all";

export function preload() {
    document.addEventListener('DOMContentLoaded', () => {
        countResources();
    });

    window.addEventListener('load', () => {
        // Убедимся, что прогресс установлен на 100% по завершении загрузки страницы
        if (loadedResources < totalResources) {
            loadedResources = totalResources;
            updatePreloadCounter();
            if(document.querySelector('.preload__counter') && document.querySelector('.preload__counter').querySelector('span').innerText.includes('100')) {
                const preload = document.querySelector('.preload');
                const preloadObjects = preload.querySelectorAll('.preload__object');
                if(preload) preload.style.background = 'none';
                if(preload) preload.classList.add('non-active')
                if(preloadObjects.length > 0) {
                    preloadObjects.forEach((obj, index)=> {
                        gsap.fromTo(obj,
                            { 
                                y: '-2rem',
                            }, 
                            { 
                                y: '100%', 
                                duration: .5,
                                delay: index * .25,
                            }
                        );
                    });
                }
                setTimeout(() => {
                    if(preload) {
                        const customCursor = document.querySelector('.custom-cursor');
                        preload.parentElement.removeChild(preload);
                        document.documentElement.style.removeProperty('overflow');
                        if(window.innerWidth > 768) {
                            if(customCursor) customCursor.style.display = 'flex';
                        }
                    }
                }, 1500);
            }
        }
    });
}
const preloadCounter = document.querySelector('.preload__counter');


let totalResources = 0;
let loadedResources = 0;

function resourceLoadHandler() {
    loadedResources++;
    updatePreloadCounter();
}

function updatePreloadCounter() {
    const percentComplete = (loadedResources / totalResources) * 100;
    if(preloadCounter) {
        preloadCounter.querySelector('span').innerText = percentComplete.toFixed(2);
    };
}

function countResources() {
    const elements = document.querySelectorAll('img, script, link[rel="stylesheet"], iframe');
    totalResources = elements.length;

    elements.forEach(element => {
        if (element.complete) {
            // Если ресурс уже загружен
            loadedResources++;
        } else {
            // Добавляем обработчики событий load и error
            element.addEventListener('load', resourceLoadHandler);
            element.addEventListener('error', resourceLoadHandler);
        }
    });

    // Обновление начального прогресса
    updatePreloadCounter();
    console.log(`Initial loaded resources: ${loadedResources}`);
    console.log(`Total resources: ${totalResources}`);
}