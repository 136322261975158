import { debounce } from "../utils.js";

export function customSlider() {
    const sliderEl = document.querySelectorAll('.range-input')
    if(sliderEl.length > 0) {
        sliderEl.forEach(el => {
            const sliderValue = el.parentElement.querySelector(".range-value");
            el.addEventListener("input", (event) => {
                const tempSliderValue = event.target.value; 
                sliderValue.textContent = tempSliderValue;
                
                const progress = (tempSliderValue / el.max) * 100;

                el.style.setProperty('--progress-width', `${progress}%`);
                // el.style.setProperty('--bg-color', '#1B2230');
                // el.classList.add('active');
                // debouncedRemoveActive(el, progress);
            });
        })
    }
}

const debouncedRemoveActive = debounce(removeActive, 100)

function removeActive(el) {
    el.classList.remove('active');
    el.style.setProperty('--bg-color', '#C9CACD');
}