import { gsap } from "gsap";

export function repairCase() {
    const repairEl = document.querySelectorAll('.repair-case')
    repairEl.forEach((el, index) => {
        gsap.fromTo(el, 
            {
                y: -150,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                duration: 0.6,
                scrollTrigger: {
                    trigger: el,
                    start: 'top center',
                    toggleActions: "play none none none",
                    once: true
                }
            }
        );
    });
}