export function addCardArticles(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const image = elem.photo ? elem.photo : 'static/assets/images/stub.png';
    
        const link = document.createElement('a');
        const hr = document.createElement('hr');

        link.href = `/articles/${elem.slug}`;
        link.classList.add('blog-card');

        let spans = elem.categories.map(category => `<span>${category}</span>`).join('')

        link.innerHTML = `
            <div class="blog-card__image">
                    <img src="${image}" alt="icon">
            </div>
            <div class="blog-card__title-block">
                <div class="blog-card__title-block__title">
                    ${elem.title}
                </div>
                <div class="blog-card__title-block__info">
                    <span class="after">
                        ${elem.created_at}
                    </span>
                    ${spans}
                </div>
            </div>
            <div class="blog-card__info-block">
                <div class="blog-card__info-block__text">
                    ${elem.content}
                </div>
                <div class="link link_size_small link_light_subdued">
                    Подробнее
                </div>
            </div>
        `;

        const img = link.querySelector('.blog-card__image');
        if(window.innerWidth > 768) {
            link.addEventListener('mouseenter',()=>{
                const hoverBlog = document.querySelector('.blog-catalog-section');
                const blogCards = hoverBlog.querySelectorAll('.blog-card');    
                img.style.opacity = 1;
                blogCards.forEach(notHoveredCard=>{
                    if(notHoveredCard !== link) {
                        notHoveredCard.style.opacity = .4;
                    }
                });
            });
            link.addEventListener('mouseleave',()=>{
                const hoverBlog = document.querySelector('.blog-catalog-section');
                const blogCards = hoverBlog.querySelectorAll('.blog-card');    
                img.style.opacity = 0;
                blogCards.forEach(notHoveredCard=>{
                    notHoveredCard.style.opacity = 1;
                });
            });
        } else {
            window.addEventListener('scroll',()=>{
                const hoverBlog = document.querySelector('.blog-catalog-section');
                const allImages = hoverBlog.querySelectorAll('.blog-card__image');

                const rect = link.getBoundingClientRect();
                if(rect.top < window.innerHeight / 2 && rect.top + rect.height > window.innerHeight / 2 && rect.top > 0) {
                    link.style.opacity = 1;
                    img.style.opacity = 1;
                    img.style.maxHeight = 'calc(100% + 2rem)';
                    allImages.forEach(image=>{
                        if(image !== img) {
                            const imageDiv = image.parentNode
                            imageDiv.style.opacity = null;
                            image.style.maxHeight = null;
                        }
                    })
                }
            })
        }

    fragment.appendChild(link);
    fragment.appendChild(hr);
    })

    section.appendChild(fragment);
}