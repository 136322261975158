import { debounce, getData } from "../../utils.js";
import { addCardInList, setCardsState } from "./utils.js";

export function searcher() {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');
    if(list) {
        const input = document.getElementById('searcher-input');

        const href = new URL(window.location.href);
        const name = href.searchParams.get('name');

        input.value = name;

        input.addEventListener('input',()=>{
            getData(`/api/products/?name=${input.value}`)
                .then(res=>{
                    setNewName(input.value);
                    debouncedSendSearcher(res.results, list, scene);
                });
        });
    };
}
const debouncedSendSearcher = debounce(sendSearcher, 300);

function sendSearcher(res, list, scene) {
    list.querySelectorAll('.product-add-card').forEach(product=>{
        product.parentNode.removeChild(product)
    });

    addCardInList(res, list);
    list.setAttribute('data-next', true);
    setCardsState(scene, list);
}
function setNewName(value) {
    const href = new URL(window.location.href);
    href.searchParams.set('name', value);
    href.searchParams.set('page', 1);

    history.replaceState({}, '', href.search.toString());
}
