export function addCardFAQ(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const div = document.createElement('div');
        const hr = document.createElement('hr');
        div.classList.add('faq-catalog-section__question-block__question', 'accordion-item');

        div.innerHTML = `
            <div class="faq-catalog-section__question-block__question__main">
                <span>${elem.question}</span>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mobile-only">
                    <path d="M4.58594 15.0003L12.0002 7.58612L19.4144 15.0003L18.0002 16.4146L12.0002 10.4145L6.00015 16.4146L4.58594 15.0003Z" fill="#1B2230"/>
                </svg>

            </div>
            <div class="faq-catalog-section__question-block__question__answer">

                <div class="faq-catalog-section__question-block__question__answer__text accordion-item__body">
                    ${elem.answer}
                </div>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.58594 15.0003L12.0002 7.58612L19.4144 15.0003L18.0002 16.4146L12.0002 10.4145L6.00015 16.4146L4.58594 15.0003Z" fill="#1B2230"/>
                </svg>

            </div>
        `;

        div.addEventListener('click', () => {
            closeOtherAccordion(div);
            const bodyElem = div.querySelector('.accordion-item__body');
            const headerElem = div.querySelector('.accordion-item__header');
            if (headerElem) headerElem.classList.toggle('show');
            if (bodyElem) {
                if (bodyElem.classList.contains('show')) {
                    bodyElem.style.maxHeight = null;
                } else {
                    bodyElem.style.maxHeight = bodyElem.scrollHeight * 2 + "px";
                }
                bodyElem.classList.toggle('show');
                div.classList.toggle('show');
            }
        });
        
    fragment.appendChild(div);
    fragment.appendChild(hr);
    })

    section.appendChild(fragment);
}

function closeOtherAccordion(target) {
    const faqSection = document.querySelector('.faq-catalog-section');
    faqSection.querySelectorAll('.accordion-item').forEach(el=> {
        const bodyElem = el.querySelector('.accordion-item__body');
        const headerElem = el.querySelector('.accordion-item__header');
        if(el !== target) {
            if (bodyElem) {
                el.classList.remove('show');
                bodyElem.classList.remove('show');
                bodyElem.style.maxHeight = null;
            }
            if (headerElem) headerElem.classList.remove('show');
        }
    })
}