export function hoverChangeDocumentation() {
    const hoverDocumentation = document.querySelectorAll('.documentation-simple-section');

    if(hoverDocumentation.length > 0) {
        hoverDocumentation.forEach(section=>{
            const hoverImageBlock = section.querySelector('.documentation-simple-section__documentation-image');
            const hoverImages = hoverImageBlock.querySelectorAll('img')
    
            const cards = section.querySelectorAll('.stages-block__item');
            const hr = section.querySelectorAll('hr');
            cards.forEach((card, index)=>{
                card.addEventListener('mouseover',()=>{
                    hr.forEach((hr,i)=>{
                        if(index == i) {
                            hr.classList.add('active');
                        } else {
                            hr.classList.remove('active');
                        }
                    })
                    const imgIndex = card.getAttribute('data-image-index');
                    const link = card.querySelector('.stages-block__item__link-block');
                    cards.forEach(item=>{
                        const notHoveredLink = item.querySelector('.stages-block__item__link-block')
                        if(item == card) {
                            link.style.opacity = 1;
                        } else {
                            notHoveredLink.style.opacity = 0;
                        }
                    })
                    hoverImages.forEach(img=>{
                        const imageHoveredData = img.getAttribute('data-image');
                        if(imgIndex == imageHoveredData) {
                            img.style.opacity = 1;
                            setTimeout(() => {
                                img.style.display = 'block';
                            }, 300);
                        } else {
                            img.style.opacity = 0;
                            setTimeout(() => {
                                img.style.display = 'none';
                            }, 300);
                        }
                    })
                })
            })            
        })
    }
}