import { getRem } from "../../slider.js";

export function addCardCase(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const id = elem.id > 9 ? `${elem.id}/` : `0${elem.id}/`;

        const link = document.createElement('a');
        const hr = document.createElement('hr');

        link.href=`/cases/${elem.slug}`;
        link.classList.add('repair-case');

        const allPhotos = elem.photos.map(photo => `
        <div class="swiper-slide">
            <div class="repair-case__card">
                <img src="${photo.photo}" alt="icon">
            </div>
        </div>`).join('');

        link.innerHTML = `
            <div class="repair-case__title-block">
                <div class="repair-case__title-block__number">
                    ${id}
                </div>
                <div class="repair-case__title-block__title">
                    ${elem.name}
                </div>
                <div class="link link_size_basic link_light_primary">
                    Смотреть кейс
                </div>
            </div>
            <div class="repair-cases-section__slider">
                <div class="swiper repair-cases_slider">
                    <div class="swiper-wrapper">
                        ${allPhotos}
                    </div>
                </div>
            </div>
        `;

        const slider = link.querySelector('.repair-cases_slider')
        const repairCase = new Swiper(slider,{
            slidesPerView: 2,
            spaceBetween: getRem() * 1,
            freeMode: true,
            breakpoints: {
                // when window width is >= 320px
                769: {
                    slidesPerView: 4,
                    spaceBetween: getRem() * 2.5,
                },
            },
            mousewheel: {
                forceToAxis: true
            }
        })


        
    fragment.appendChild(link);
    fragment.appendChild(hr);
    })
    section.appendChild(fragment);
}