import { 
    animationDef, 
    decorationsAnimation 
} from "./utils.js";

export function offerBasic() {
    const offerBasicSections = document.querySelectorAll('.offer-basic-section');
    if(offerBasicSections.length > 0) {
        offerBasicSections.forEach((section) => {
            const socials = section.querySelectorAll('.social-icon');
            const title = section.querySelector('.offer-basic-section__title');
            const star = section.querySelector('.star-button');

            decorationsAnimation(section)
            socials.forEach((btn, index)=> {
                let direction;
                let value;
                let delay;

                switch (index) {
                    case 0:
                        direction = 'x';
                        value = -75;
                        delay = 1;
                        break;
                    case 1:
                        direction = 'x';
                        value = 75;
                        delay = 1;
                        break;
                }
                
                animationDef(btn, direction, value, section, delay)
                
            });
            animationDef(star, 'y', 0, section, 0.5);
            animationDef(title, 'x', 100, section);
        });
    }
};