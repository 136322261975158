import { videoChangeStates } from "../../video-controls.js";

export function addVideo(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const div = document.createElement('div');

        div.classList.add('video-gallery-section__item');

        div.innerHTML = `
            <div class="video-gallery-section__item__video">
                <div class="icon-button icon-button_size_basic icon-button_primary play_stop_video">
                    <div class="svg svg_pause">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66675 4H14.0001V28H4.66675V4Z" fill="#F4F4F4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0001 4H27.3334V28H18.0001V4Z" fill="#F4F4F4"/>
                        </svg>
                    </div>
                    <div class="svg svg_play">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 2.61719L30.6176 15.9996L8 29.3925V2.61719Z" fill="#1B2230"/>
                        </svg>
                    </div>
                </div>
                <video src="${elem.file}"></video>
            </div>
            <div class="video-gallery-section__item__text">
                ${elem.name}
            </div>
        `;
        const btn = div.querySelector('.play_stop_video');

        const video = btn.parentNode.querySelector('video');
        btn.addEventListener('click',()=>{
            videoChangeStates(btn, video);
        });
        video.addEventListener('ended', function() {
            btn.classList.remove('icon-button_primary_active');
        });


        
    fragment.appendChild(div);
    })
    section.appendChild(fragment);
}