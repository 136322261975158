import { 
    appendNewCard, 
    getClickPositionDirection, 
    getPercentsOfClick, 
    setCardsState, 
    showContextualMenu 
} from "./utils.js";

export function clickOnScene() {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');
    if(scene) {
        const productCardOi = scene.querySelector('.product-card-oi'); 
        const addBtn = scene.querySelector('.add-button');
        const img = scene.querySelector('img');
        img.addEventListener('click',(evt)=>{
            const sceneFirst = checkForScenarioScene(list);
            const productCards = list.querySelectorAll('.product-add-card');

            // Вычисления позиции нажатия
            const { xPercent, yPercent } = getPercentsOfClick(evt, scene);
            const X_PERCENT = xPercent;
            const Y_PERCENT = yPercent;
            const direction = getClickPositionDirection(evt, scene);
            
            if(sceneFirst) {
                scene.setAttribute('data-state', 'active');

                if(productCardOi) {
                    productCardOi.className = 'product-card-oi';
                    productCardOi.classList.add(`${direction}`);
        
                    productCardOi.style.left = `${X_PERCENT}%`;
                    productCardOi.style.top = `${Y_PERCENT}%`;
                    productCardOi.style.opacity = 1;
        
                    addBtn.style.left = `${X_PERCENT}%`;
                    addBtn.style.top = `${Y_PERCENT}%`;
                    addBtn.style.display = 'flex';



                    productCards.forEach(card=>{
                        const btn = card.querySelector('.product-add-card__btn')
                        btn.classList.remove('active');
                    });
                };
            } else {
                productCards.forEach(card=>{
                    const btn = card.querySelector('.product-add-card__btn');
                    if(btn.classList.contains('active')) {
                        const id = card.getAttribute('data-id');
                        showContextualMenu(list, id);
                        
                        appendNewCard(scene, list, card, X_PERCENT, Y_PERCENT);
                        setCardsState(scene, list);
                        list.setAttribute('data-state', 'false');
                    }
                });
            };
        });

        const clearFromFirstBtn = document.querySelector('.close_first_scenario');
        if(!clearFromFirstBtn) return;
        clearFromFirstBtn.addEventListener('click',()=>{
            clearFromFirst(scene);
        });
    }
};

function checkForScenarioScene(list) {
    let sceneFirst = false;
    
    if(list.getAttribute('data-state') == 'false') sceneFirst = true;

    return sceneFirst;
}

export function clearFromFirst(scene) {
    const productCardOi = scene.querySelector('.product-card-oi'); 
    const addBtn = scene.querySelector('.add-button');
    
    productCardOi.style.left = '125%';
    productCardOi.style.top = '125%';
    productCardOi.style.opacity = 0;

    addBtn.style.display = null;
}