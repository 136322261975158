export function addPhoto(data, section) {
    const fragment = document.createDocumentFragment();

    data.results.forEach(elem=>{
        const div = document.createElement('div');

        div.classList.add('photo-gallery-section__image');

        div.innerHTML = `
            <img loading="lazy" src="${elem.file}" alt="${elem.name}">
        `;

        console.log(div);

        fragment.appendChild(div);
    });
    section.appendChild(fragment);
}