export function copyText() {
    const copyItems = document.querySelectorAll('.requisites-section__item');
    
    if(copyItems.length > 0) {
        copyItems.forEach(copyItem => {
            const copyBtn = copyItem.querySelector('.copy-btn');
            const copyText = copyItem.querySelector('.requisites-section__item__value').innerText;
    
            copyBtn.addEventListener('click', function() {
                navigator.clipboard.writeText(`${copyText} - "скопировано с сайта SVR"`);
            });
        });
    }

    const copyAllBtn = document.getElementById('copy_requisetes_all');
    if(copyAllBtn) {
        copyAllBtn.addEventListener('click',()=>{
            let copiedAllValue = [];
            copyItems.forEach(item=>{
                const copyText = item.querySelector('.requisites-section__item__value').innerText;
                copiedAllValue.push(copyText);
            });

    
            navigator.clipboard.writeText(copiedAllValue.join(',\n' + '-' + ' "скопировано с сайта SVR"'));
            
        })
    }
}