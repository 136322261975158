import { findParentWithClass } from "./utils.js";

export function videoControls() {
    const playStop = document.querySelectorAll('.play_stop_video');

    if(playStop.length > 0) {
        playStop.forEach(btn=>{
            const video = btn.parentNode.querySelector('video');
            btn.addEventListener('click',()=>{
                videoChangeStates(btn, video);
            });
            video.addEventListener('ended', function() {
                btn.classList.remove('icon-button_primary_active');
            });
        });
    };
    const videoBtns = document.querySelectorAll('.open-video-btn');
    if(videoBtns.length > 0) {
        videoBtns.forEach(btn=>{
            const videoModal = document.querySelector('.video-modal');
            const video = videoModal.querySelector('video');
            const modalBtn = videoModal.querySelector('.icon-button');
            btn.addEventListener('click',()=>{
                if(btn.classList.contains('video-content')) {
                    const section = findParentWithClass(btn, 'video-content-section');
                    const slideActive = section.querySelector('.swiper-slide-active');
                    changeVideoSrc(slideActive);
                } else {
                    changeVideoSrc(btn);
                }
                
                video.play();
                openVideoModal(btn);
            });
            modalBtn.addEventListener('click',()=>{
                video.pause();
                closeVideoModal(modalBtn)
            })
            if(video) {
                video.addEventListener('ended', function() {
                    closeVideoModal(modalBtn)
                });
            }
        });
    };
}

export function videoChangeStates(button, video) {
    if(video.paused || video.ended) {
        video.play()
    } else {
        video.pause()
    }
    // Play/Pause button
    if (video.paused || video.ended) {
        button.classList.add('icon-button_primary');
        button.classList.remove('icon-button_primary_active');
    } else {
        button.classList.add('icon-button_primary_active');
    }
}

const videoModal = document.querySelector('.video-modal');

function changeVideoSrc(elem) {
    const newVideoSrc = elem.getAttribute('data-src');
    if(newVideoSrc) videoModal.querySelector('video').src = newVideoSrc;
}

function openVideoModal(btn) {
    btn.classList.add('icon-button_primary_active');
    document.documentElement.style.overflow = "hidden";
    videoModal.style.display = 'flex';
    setTimeout(() => {
        videoModal.style.opacity = 1;
    }, 50);
    setTimeout(() => {
        btn.classList.remove('icon-button_primary_active');
    }, 350);
}

function closeVideoModal(btn) {
    btn.classList.remove('icon-button_primary_active');
    videoModal.style.opacity = 0;
    setTimeout(() => {
        videoModal.style.display = null;
        btn.classList.add('icon-button_primary_active');
        document.documentElement.style.overflow = null;
    }, 300);
}