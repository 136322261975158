import {findParentWithClass} from "./utils.js";

export function modals() {
    const closeButtons = document.querySelectorAll('.close-modal');
    if (closeButtons.length) {
        closeButtons.forEach(button => {
            button.addEventListener('click', () => {
                const modal = findParentWithClass(button, 'modal');
                if (modal) {
                    modal.setAttribute('data-modal-state', 'close');
                }
            })
        })
    }
}