import { 
    appendNewCard, 
    getPercentsOfClick, 
    setCardsState, 
    showContextualMenu 
} from "./utils.js";

export function dragNdrop(card) {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');
    if(scene) {
        const sceneImg = scene.querySelector('img');
        const fakeCard = document.querySelector('.fake-card');

        const btn = card.querySelector('.product-add-card__btn');
        const id = card.getAttribute('data-id')
        card.onmousedown = (e) => { 
            if(e.target !== btn) {
                const title = card.querySelector('.product-add-card__text-block__title').innerText;
                const price = card.querySelector('.product-add-card__text-block__title_sub').innerText;
                const image = card.querySelector('img').src;
                fakeCard.querySelector('img').src = image;
                fakeCard.querySelector('.fake-card__text-block__title').innerText = title.trim();
                fakeCard.querySelector('.fake-card__text-block__title_sub').innerText = price.trim();

                document.onmouseup = (e) => {
                    if(e.target == sceneImg) {
                        const { xPercent, yPercent } = getPercentsOfClick(e, scene);
                        const X_PERCENT = xPercent;
                        const Y_PERCENT = yPercent;

                        showContextualMenu(list, id);

                        appendNewCard(scene, list, card, X_PERCENT, Y_PERCENT);
                        setCardsState(scene, list);
                    }
                    document.onmousemove = null;
                    document.onmouseup = null;
                    fakeCard.style.display = 'none';
                };
            
                document.onmousemove = (e) => {
                    moveAt(e);
                    
                    if(e.target == sceneImg) {
                        const sceneImgRect = sceneImg.getBoundingClientRect();
                        const topPosition = e.pageY - sceneImgRect.y;
                        const leftPosition = e.pageX - sceneImgRect.x;

                        let direction = '';
                        topPosition > sceneImgRect.height / 2 ? direction = direction + 'bottom' : direction = direction + 'top';
                        leftPosition > sceneImgRect.width / 2 ? direction = direction + 'right' : direction = direction + 'left'; 

                        fakeCard.className = `fake-card ${direction}`;
                    }
                };
            }
        };

        function moveAt(e) {
            fakeCard.style.left = e.pageX + 1 + 'px';
            fakeCard.style.top = e.pageY + 1 + 'px';
            fakeCard.style.display = 'flex';
        }
    }
}