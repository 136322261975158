import { 
    animationDef, 
    decorationsAnimation 
} from "./utils.js";

export function videoAboutDesign() {
    const offerBasicSections = document.querySelectorAll('.video-about-design-section');
    if(offerBasicSections.length > 0) {
        offerBasicSections.forEach((section) => {
            const title = section.querySelector('.video-about-design-section__title');
            const star = section.querySelector('.button');

            decorationsAnimation(section);
            animationDef(star, 'y', 0, section, 0.5);
            animationDef(title, 'x', 100, section);
        });
    }
};