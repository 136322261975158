import { createHoverChangePhotoEffect } from "./hover-change-photo.js";
import { getData } from "./utils.js";

export function filtersDinamic() {
    const filtersSections = document.querySelectorAll('.__section__filters__dinamic');

    if(filtersSections.length > 0) {
        filtersSections.forEach(section=>{
            const desktopBlock = section.querySelector('.__block__filters__dinamic');
            const mobileBlock = section.querySelector('.__block-mobile__filters__dinamic');
            const filterInputs = section.querySelectorAll('input[type="radio"]');
    
            filterInputs.forEach(input=>{
                const inputName = input.getAttribute('name');
                const id = input.getAttribute('data-filter');
    
                input.addEventListener('click',()=>{
                    if(input.classList.contains('desktop')) {
                        appendFragment(desktopBlock, inputName, id);
                    };
                    if(input.classList.contains('mobile')) {
                        appendFragment(mobileBlock, inputName, id);
                    };
                });
            });
        });
    } 
}

async function appendFragment(block, name, category) {
    const fragment = document.createDocumentFragment();

    await addCard(fragment, name, category);

    block.innerHTML = '';
    block.appendChild(fragment);
}
async function addCard(fragment, name, category) {
    if(name == 'faqDinamicFilters') {
        const res = await getData(`/api/faq/?category=${category}`);
        res.results.forEach((result, index) => {
            const div = document.createElement('div');
            const hr = document.createElement('hr');

            div.classList.add('question', 'accordion-item');
            div.innerHTML = `
                <div class="question__number">
                    0${index + 1}/
                </div>
                <div class="question__main">
                    ${result.question}
                </div>
                <div class="question__answer">
                    <div class="question__answer__text accordion-item__body">
                        <span>${result.answer}</span>
                    </div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.58594 15.0003L12.0002 7.58612L19.4144 15.0003L18.0002 16.4146L12.0002 10.4145L6.00015 16.4146L4.58594 15.0003Z" fill="#1B2230"/>
                    </svg>
                </div>
            `;
            div.addEventListener('click', () => {
                const bodyElem = div.querySelector('.accordion-item__body');;
                if (bodyElem) {
                    if (bodyElem.classList.contains('show')) {
                        bodyElem.style.maxHeight = null;
                    } else {
                        bodyElem.style.maxHeight = bodyElem.scrollHeight * 2 + "px";
                    }
                    bodyElem.classList.toggle('show');
                    div.classList.toggle('show');
                };
                const otherDivs = div.parentNode.querySelectorAll('.accordion-item');
                otherDivs.forEach(other=>{
                    const otherBody = other.querySelector('.accordion-item__body');;
                    if(other !== div) {
                        otherBody.style.maxHeight = null;
                        otherBody.classList.remove('show');
                        other.classList.remove('show');
                    };
                });
            });
            fragment.appendChild(div);
            fragment.appendChild(hr);
        });
    }
    if(name == 'faqDinamicFiltersMobile') {
        const res = await getData(`/api/faq/?category=${category}`);
        res.results.forEach((result, index) => {
            const div = document.createElement('div');
            div.classList.add('swiper-slide');
            div.innerHTML = `
                <div class="faq-card">
                    <div class="question accordion-item">
                        <div class="question__number">
                            0${index + 1}/
                        </div>
                        <div class="question__main">
                            ${result.question}
                        </div>
                        <div class="question__answer">
                            <div class="question__answer__text faq-section__question-block-mobile__text">
                                ${result.answer}
                            </div>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.58594 15.0003L12.0002 7.58612L19.4144 15.0003L18.0002 16.4146L12.0002 10.4145L6.00015 16.4146L4.58594 15.0003Z" fill="#1B2230"/>
                            </svg>
                        </div>
                    </div>
                </div>
            `;
            fragment.appendChild(div);
        });
    }
    if(name == 'serviceDinamicFilters') {
        const imageBlock = document.createElement('div');
        imageBlock.classList.add('services-section__info-block__image', 'hover-change-section__image')

        const res = await getData(`/api/services/?category=${category}`);
        res.results.slice(0, 4).forEach((result, index) => {
            const image = document.createElement('img');

            image.src = result.main_photo ? result.main_photo : '/static/assets/images/stub.png';
            image.setAttribute('data-image', index + 1);

            const card = document.createElement('a');
            const hr = document.createElement('hr');

            card.href = `/services/${result.slug}`;
            card.classList.add('service-card', 'hover-change-section__hover-card');
            card.setAttribute('data-image-index', index + 1);
            card.innerHTML = `
                <div class="service-card__title-block">
                    <div class="service-card__title-block__title">
                        ${result.name}
                    </div>
                    <div class="service-card__title-block__info">
                    <span>
                        от ${result.price} ₽
                    </span>
                    <span class="brand-star">
                        ${result.realization_period}
                    </span>
                    </div>
                </div>
                <div class="service-card__info-block">
                    <div class="service-card__info-block__text">
                        ${result.small_description}
                    </div>
                    <button class="link link_size_small link_light_subdued">
                        Подробнее
                    </button>
                </div>
            `;

            const section = document.querySelector('.services-section');
            const hoverCards = section.querySelectorAll('.hover-change-section__hover-card');

            imageBlock.appendChild(image);
            fragment.appendChild(card);
            fragment.appendChild(hr);
        })

        setTimeout(() => {
            createHoverChangePhotoEffect(
                '.hover-change-section', 
                '.hover-change-section__image',
                '.hover-change-section__image img',
                '.hover-change-section__hover-card',
                '.hover-change-section__hover-btn'
            );
        }, 100);
            
        fragment.appendChild(imageBlock);
    }

    if(name == 'tariffDinamicFilters') {
        const imageBlock = document.createElement('div');
        imageBlock.classList.add('take-section__image-adaptive', 'hover-change-section__image', 'zoom-image')

        const tariffInp = document.querySelector('input[name="tariff_service_id"]');
        const tariffId = tariffInp.value;

        const res = await getData(`/api/tariffs/?category=${category}&service=${tariffId}`);
        res.results.slice(0, 4).forEach((result, index) => {
            const image = document.createElement('img');

            image.src = result.photo ? result.photo : '/static/assets/images/stub.png';
            image.setAttribute('data-image', index + 1);

            const card = document.createElement('div');
            const hr = document.createElement('hr');

            let promise = '';
            if(result.promises) {
                result.promises.forEach(item => {
                    promise += `
                        <li class="brand-star">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6654 8.00016C9.33203 8.00016 7.9987 3.55572 7.9987 1.3335C7.9987 3.55572 6.66536 8.00016 1.33203 8.00016C3.55425 8.00016 7.9987 9.3335 7.9987 14.6668C7.9987 9.3335 12.4431 8.00016 14.6654 8.00016Z" fill="#1B2230"/>
                            </svg>
                            ${item}
                        </li>
                    `
                });
            }

            card.classList.add('tariff-card', 'hover-change-section__hover-card');
            card.setAttribute('data-image-index', index + 1);
            card.innerHTML = `
                <div class="tariff-card__title-block">
                    <div class="tariff-card__title-block__title">
                        ${result.name}
                    </div>
                    <div class="tariff-card__title-block__title_sub">
                        ${result.price} ₽ / м²
                    </div>
                </div>
                <div class="tariff-card__information">
                    ${result.description}
                </div>
                <div class="tariff-card__description">
                    <ul>
                        ${promise}
                    </ul>
                    <div class="hover-change-section__hover-btn">
                        <a href="{% url 'core:feedback' %}"
                            class="button button_size_small button_primary_light">
                            Заказать
                        </a>
                    </div>
                </div>
            `;

            const section = document.querySelector('.take-section');
            const hoverCards = section.querySelectorAll('.hover-change-section__hover-card');
            

            imageBlock.appendChild(image);
            fragment.appendChild(card);
            fragment.appendChild(hr);
        })

        setTimeout(() => {
            createHoverChangePhotoEffect(
                '.hover-change-section', 
                '.hover-change-section__image',
                '.hover-change-section__image img',
                '.hover-change-section__hover-card',
                '.hover-change-section__hover-btn'
            );
        }, 100);
            
        fragment.appendChild(imageBlock);
    }
}