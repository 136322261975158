export function partnersHover() { 
    const partnersSection = document.querySelectorAll('.partners-section'); 
    
    if(partnersSection.length > 0) {
        partnersSection.forEach(section=>{ 
            const blockPartner = section.querySelectorAll('.partners-section__block__partner'); 
    
            const arrayPartners = []; 
            blockPartner.forEach(el=>{ 
                arrayPartners.push(el); 
            }); 
    
            let i = blockPartner.length; 
            let finalArr = []; 
            if(window.innerWidth > 768) { 
                while(!Number.isInteger(i / 5)) { 
                    i++; 
                }; 
    
                while(i / 5 !== 0) { 
                    finalArr.push(arrayPartners.slice(i - 5, i)) 
                    arrayPartners.slice(i - 5, i).forEach((el, i)=> { 
                        const imageAdaptive = el.querySelector('.image-adaptive'); 
                        if(i >= 3) { 
                            imageAdaptive.classList.add('right'); 
                        } else { 
                            imageAdaptive.classList.add('left'); 
                        }; 
                    }); 
        
                    i = i - 5; 
                }; 
            } else { 
                while(!Number.isInteger(i / 3)) { 
                    i++; 
                }; 
        
                while(i / 3 !== 0) { 
                    finalArr.push(arrayPartners.slice(i - 3, i)) 
                    arrayPartners.slice(i - 3, i).forEach((el, i)=> { 
                        const imageAdaptive = el.querySelector('.image-adaptive'); 
                        if(i > 1) { 
                            imageAdaptive.classList.add('right'); 
                        } else if (i == 1) { 
                            imageAdaptive.classList.add('left'); 
                            imageAdaptive.classList.add('mobile'); 
                        } else { 
                            imageAdaptive.classList.add('left'); 
                        } 
                    }); 
        
                    i = i - 3; 
                }; 
            } 
                
            Array.from(finalArr[0]).forEach(el=>{ 
                const imageAdaptive = el.querySelector('.image-adaptive'); 
                imageAdaptive.classList.add('bottom'); 
            }); 
        });
    } 
}