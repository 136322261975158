import { debounce } from './utils.js';

import { gsap } from "gsap/all";

export function priceCalculator() {
    const priceCalculatorSection = document.querySelectorAll('.price-calculator-section');
    
    if(priceCalculatorSection.length > 0) {
        priceCalculatorSection.forEach(section => {
            const priceCalcButtons = section.querySelectorAll('.price-calculator-button');
            const inputRange = section.querySelector('.range-input');
            const imageScale = section.querySelector('.price-calculator-section__image-block__image');
            inputRange.value = 1;
            inputRange.step = 0.1;
    
            updateWithRangeValue(section, 1);
    
            priceCalcButtons.forEach(btn => {
                btn.addEventListener('click', () => {
                    const dataImage = btn.getAttribute('data-image');
    
                    updateWithButtonValue(section, dataImage);
                });
            });
    
            inputRange.addEventListener('input', () => {
                const progress = window.getComputedStyle(inputRange).getPropertyValue('--progress-width');
                let progressMax = Math.max(parseInt(progress), 55);
                imageScale.style.transform = `scale(${progressMax}%)`;
                updateWithRangeValue(section, inputRange.value);
            });
        });
    }
}

function updateButton(section, number) {
    const priceCalcButtons = section.querySelectorAll('.price-calculator-button');
    priceCalcButtons.forEach(btn => {
        const dataImage = btn.getAttribute('data-image');
        if (parseInt(dataImage) == number) btn.checked = true;
    });
}

function updateImage(section, number) {
    const images = section.querySelector('.price-calculator-section__image-block__image').querySelectorAll('img');
    images.forEach(img => {
        const dataSelected = img.getAttribute('data-selected');
        if (dataSelected == number) {
            img.style.display = 'flex';
        } else {
            img.style.display = 'none';
        }
    });
}

// const debounceUpdateImage = updateImage;
// const debounceUpdateButtons = updateButton;

function updateWithButtonValue(section, number) {
    const inputRange = section.querySelector('.range-input');
    const parsedNumber = parseInt(number);
    let value = 0;
    switch (parsedNumber) {
        case 1:
            value = 0;
            break;
        case 2:
            value = 25;
            break;
        case 3:
            value = 50;
            break;
        case 4:
            value = 75;
            break;
        case 5:
            value = 100;
            break;
        case 6:
            value = 125;
            break;
    }
    inputRange.value = value + 1;

    const event = new Event('input', {
        bubbles: true,
        cancelable: true,
    });
    inputRange.dispatchEvent(event);
    updateImage(section, number);
}

function updateWithRangeValue(section, value) {
    let number = 1;
    const parsedVal = parseFloat(value);
    
    switch (true) {
        case (parsedVal >= 0 && parsedVal <= 25):
            number = 1;
            break;
        case (parsedVal > 25 && parsedVal <= 50):
            number = 2;
            break;
        case (parsedVal > 50 && parsedVal <= 75):
            number = 3;
            break;
        case (parsedVal > 75 && parsedVal <= 100):
            number = 4;
            break;
        case (parsedVal > 100 && parsedVal <= 125):
            number = 5;
            break;
        case (parsedVal > 125 && parsedVal <= 150):
            number = 6;
            break;
    }
    updateImage(section, number);
    updateButton(section, number);
}
