export function dialogSuccess(title = null, message = null) {
    const dialogSuccess = document.querySelector('.dialog-success');
    if (dialogSuccess) {

        const titleEl = dialogSuccess.querySelector('#dialogSuccessTitle');
        const messageEl = dialogSuccess.querySelector('#dialogSuccessMessage');


        if (title && message) {
            titleEl.innerText = title;
            messageEl.innerText = message;
        }

        const dialogSuccessBtn = dialogSuccess.querySelector('.dialog-success__close-btn');
        dialogSuccess.classList.add('active');
        setTimeout(() => {
            dialogSuccess.classList.remove('active');
        }, 5000);
        dialogSuccessBtn.addEventListener('click', () => {
            dialogSuccess.classList.remove('active');
        });
    }
}
