import { 
    animationDef,
    decorationsAnimation 
} from "./utils.js";

export function videoContent() {
    const videoContentSections = document.querySelectorAll('.video-content-section');
    if (videoContentSections.length > 0) {
        videoContentSections.forEach((section) => {
            const title = section.querySelector('.video-content-section__title');

            decorationsAnimation(section)
            animationDef(title, 'x', 100, section, 0);
        });
    }
};