import { addCardArticles } from "./pages/articles/addCard.js";
import { addCardCase } from "./pages/cases/addCards.js";
import { addCardFAQ } from "./pages/faq/addCards.js";
import { addPhoto } from "./pages/gallery/addPhoto.js";
import { addVideo } from "./pages/gallery/addVideo.js";
import { addCardReviews } from "./pages/reviews/addCard.js";
import { addCardServices } from "./pages/services/addCard.js";
import { addCardVacanies } from "./pages/vacancies/addCards.js";
import { getCurrentQuery, getData, setNewQuery } from "./utils.js";

export function endlessPagination() {
    const paginationElem = document.querySelectorAll('.endless-pagination__elem');

    if(paginationElem.length > 0) {
        paginationElem.forEach(elem=>{
            const paginationAttr = elem.getAttribute('data-attr');
    
            if(paginationAttr) {
                const reloadedPageHref = new URL(window.location.href);
                reloadedPageHref.searchParams.set('page', 1);
                history.replaceState({}, '', reloadedPageHref.search.toString());
    
                getData(`/api/${paginationAttr}/${window.location.search}`)
                    .then(data=>{
                        let section = '';
    
                        if(data.next == null) elem.parentElement.removeChild(elem);
    
                        if(paginationAttr == 'gallery/get-videos') {
                            section = document.querySelector('.video-gallery-section__info-block');
                            addVideo(data, section);
                        }
                        if(paginationAttr == 'gallery/get-photos') {
                            section = document.querySelector('.photo-gallery-section__info-block');
                            addPhoto(data, section);
                        }
                        if(paginationAttr == 'services') {
                            section = document.querySelector('.services-catalog-section__info-block');
                            addCardServices(data.results, section);
                        }
                        if(paginationAttr == 'articles') {
                            section = document.querySelector('.blog-catalog-section__card-block');
                            addCardArticles(data.results, section);
                        }
                        if(paginationAttr == 'vacancy') {
                            section = document.querySelector('.vacancies-section__vacancy-cards');
                            addCardVacanies(data.results, section);
                        }
                        if(paginationAttr == 'reviews') {
                            section = document.querySelector('.reviews-section__vtestimonial-cards');
                            addCardReviews(data.results, section);
                        }
                        if(paginationAttr == 'faq') {
                            section = document.querySelector('.faq-catalog-section__question-block');
                            addCardFAQ(data.results, section);
                        }
                        if(paginationAttr == 'cases') {
                            section = document.querySelector('.cases-catalog-section__info-block');
                            addCardCase(data.results, section);
                        }
                    });
    
                elem.addEventListener('click',()=>{
                    const href = new URL(window.location.href);
                    let pageValue = href.searchParams.get('page');
                    pageValue = parseInt(pageValue) + 1;
                    href.searchParams.set('page', pageValue.toString());
                    history.replaceState({}, '', href.search.toString());
                    
                    getData(`/api/${paginationAttr}/${window.location.search}`)
                        .then(data=>{
                            let section = '';
                            if(data.next !== null) {
                                // setNewPage()
                            } else {
                                elem.parentElement.removeChild(elem);
                            }
    
                            if(paginationAttr == 'gallery/get-videos') {
                                section = document.querySelector('.video-gallery-section__info-block');
                                addVideo(data, section);
                            }
                            if(paginationAttr == 'gallery/get-photos') {
                                section = document.querySelector('.photo-gallery-section__info-block');
                                addPhoto(data, section);
                            }
                            if(paginationAttr == 'services') {
                                section = document.querySelector('.services-catalog-section__info-block');
                                addCardServices(data.results, section);
                            }
                            if(paginationAttr == 'articles') {
                                section = document.querySelector('.blog-catalog-section__card-block');
                                addCardArticles(data.results, section);
                            }
                            if(paginationAttr == 'vacancy') {
                                section = document.querySelector('.vacancies-section__vacancy-cards');
                                addCardVacanies(data.results, section);
                            }
                            if(paginationAttr == 'reviews') {
                                section = document.querySelector('.reviews-section__vtestimonial-cards');
                                addCardReviews(data.results, section);
                            }
                            if(paginationAttr == 'faq') {
                                section = document.querySelector('.faq-catalog-section__question-block');
                                addCardFAQ(data.results, section);
                            }
                            if(paginationAttr == 'cases') {
                                section = document.querySelector('.cases-catalog-section__info-block');
                                addCardCase(data.results, section);
                            }
                        });
                });
            }
        });
    }

}


export function setNewPage() {
    console.log('123');
    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');
    if(pageValue !== null) {
        pageValue = parseInt(pageValue) + 1;
        href.searchParams.set('page', pageValue.toString());
    } else {
        href.searchParams.set('page', 2);
    }
    history.replaceState({}, '', href.search.toString());
}
