import { gsap } from "gsap/all";

export function animationDef(elem, direction, value, trig, delay) {
    if(direction == 'y') {
        gsap.fromTo(elem,
            {
                y: value,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                duration: 0.5,
                delay: delay,
                scrollTrigger: {
                    trigger: trig,
                    start: 'top bottom',
                    toggleActions: "play none none none",
                    once: true
                }
            }
        );
    } else if(direction == 'x') {
        gsap.fromTo(elem,
            {
                x: value,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
                duration: 0.5,
                delay: delay,
                scrollTrigger: {
                    trigger: trig,
                    start: 'center bottom',
                    toggleActions: "play none none none",
                    once: true,
                }
            }
        );
    }
}

export function decorationsAnimation(section, delay) {
    const decorations = section.querySelectorAll('.decoration-element');

    decorations.forEach(dec=> {
        if(dec.classList.contains('decoration-element_top')) {
            animationDef(dec, 'y', -100, section, delay)
        } else if(dec.classList.contains('decoration-element_left')) {
            animationDef(dec, 'x', -100, section, delay)
        } else if(dec.classList.contains('decoration-element_right')) {
            animationDef(dec, 'x', 100, section, delay)
        } else if(dec.classList.contains('decoration-element_bottom')) {
            animationDef(dec, 'y', 100, section, delay)
        }
    });
}