import {dialogSuccess} from "./dialog-success.js";


let urlIndex = 0;

export function websocketSettings(request_id) {
    const ws = new WebSocket(`ws://${window.location.host}/ws/photo/${request_id}/`);

    ws.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data.message_text) {
            dialogSuccess("Еще чуть-чуть!", "Мы уже подготавливаем изображения");
        }

        if (data.photo_url) {
            data.photo_url.urls.forEach(url => {
                addPhoto(url)
            })
            changeLoadingState()
        }
    };

    ws.onopen = function (event) {
        dialogSuccess("Ожидайте", "Нейросеть уже генерирует идеи для вашего интерьера, это может занять некоторое время");
        changeLoadingState()
    };

    ws.onclose = function (event) {
        console.log('WebSocket connection closed');
    };

    ws.onerror = function (error) {
        console.error('WebSocket error:', error);
    };
}

export function resetPhotos() {
    const images = document.querySelectorAll('.ai-image-generator-section__image-block__image');
    images.forEach(image => {
        const img = image.querySelector('img');
        if (!img.src.includes('stub.png')) {
            img.src = "/static/assets/images/stub.png";
        }
    });
    urlIndex = 0;
}

export function changeLoadingState() {
    const containers = document.querySelectorAll('.aiImageSlideContainer');

    const desktopPhotos = containers[0].querySelectorAll('.ai-image-generator-section__image-block__image');
    const mobilePhotos = containers[1].querySelectorAll('.ai-image-generator-section__image-block__image');

    desktopPhotos.forEach(photo => {
        photo.classList.toggle('is-loading');
    });

    mobilePhotos.forEach(photo => {
        photo.classList.toggle('is-loading');
    });
}

export function addPhoto(photoUrl) {
    const containers = document.querySelectorAll('.aiImageSlideContainer');

    const desktopPhotos = containers[0].querySelectorAll('.ai-image-generator-section__image-block__image');
    const mobilePhotos = containers[1].querySelectorAll('.ai-image-generator-section__image-block__image');

    desktopPhotos[urlIndex].querySelector('img').src = photoUrl;
    mobilePhotos[urlIndex].querySelector('img').src = photoUrl;
    urlIndex += 1;
}

export function neuroForm() {
    const lastRequestItems = document.querySelectorAll('.aiLastRequest');
    const searchField = document.getElementById('prompt');
    if (lastRequestItems.length) {

        lastRequestItems.forEach(request => {
            request.addEventListener('click', (e) => {
                searchField.value = e.target.innerText;
                searchField.dispatchEvent(new Event("input"));
            });
        });
    }
}