import { findParentWithClass } from "./utils.js";

export function quiz() {
    const quizSection = document.querySelector('.quiz-section');
    if(window.innerWidth > 768 && quizSection) {
        const quizItems = quizSection.querySelectorAll('.quiz-section__item');
        const imageSticky = quizSection.querySelector('.quiz-section__image-sticky');

        let prevScrollPos = window.pageYOffset;
        window.addEventListener('scroll',()=>{
            const currentScrollPos = window.pageYOffset;

            const imageRect = imageSticky.getBoundingClientRect();
            quizItems.forEach((item, index)=>{
                const itemRect = item.getBoundingClientRect();
                if(imageRect.top > itemRect.top && itemRect.bottom > imageRect.top){
                    imageSticky.querySelectorAll('img').forEach((img, imageIndex)=>{
                        if(index >= imageIndex && prevScrollPos < currentScrollPos) {
                            img.style.opacity = 1;
                        } else if(index < imageIndex && prevScrollPos < currentScrollPos) {
                            img.style.opacity = 0;
                        }

                        if (index + 1 <= imageIndex && prevScrollPos > currentScrollPos) {
                            img.style.opacity = 0;
                        } else if(index + 1 > imageIndex && prevScrollPos > currentScrollPos) {
                            img.style.opacity = 1;
                        }
                    });
                };
            });

            prevScrollPos = currentScrollPos;
        })
    }
    if(quizSection) {
        quizSection.querySelectorAll('input').forEach(input=>{
            input.addEventListener('click',()=>{
                const list = findParentWithClass(input, 'quiz-section__item__list');
                if(list.classList.contains('error')) {
                    list.classList.remove('error');
                }
            })
        })
    }
}