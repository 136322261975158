export function slider() {
    const designAbout = new Swiper('.design-about_slider',{
        slidesPerView: 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".design-about_slider__next-btn",
            prevEl: ".design-about_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const serviceCards = new Swiper('.service-card_slider',{
        slidesPerView: 1,
        spaceBetween: getRem() * 1,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".service-card_slider__next-btn",
            prevEl: ".service-card_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const personalCards = new Swiper('.personal-section_slider',{
        slidesPerView: 1,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".personal-section_slider__next-btn",
            prevEl: ".personal-section_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const blogCards = new Swiper('.blog-card_slider',{
        slidesPerView: 1,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".blog-card_slider__next-btn",
            prevEl: ".blog-card_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const videoContent = new Swiper('.video-content_slider',{
        slidesPerView: 1,
        effect: "fade",
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".video-content_slider__next-btn",
            prevEl: ".video-content_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const aboutStyles = new Swiper('.about-styles_slider',{
        slidesPerView: 1.5,
        spaceBetween: getRem() * .75,
        // loop: true,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".about-styles_slider__next-btn",
            prevEl: ".about-styles_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const faqCard = new Swiper('.faq-card_slider',{
        slidesPerView: 1,
        // loop: true,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".faq-card_slider__next-btn",
            prevEl: ".faq-card_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const postGarantee = new Swiper('.post-garantee_slider',{
        slidesPerView: 1,
        // loop: true,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".post-garantee_slider__next-btn",
            prevEl: ".post-garantee_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const filterBlock = new Swiper('.filter-block_slider',{
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: getRem() * .75,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        }
    })

    const filtersMobileOnly = new Swiper('.filters-slider-mobile',{
        slidesPerView: 3,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        }
    })
    
    const filterTariffBlock = new Swiper('.filter-tariff-block_slider',{
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: getRem() * .75,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        }
    })

    const aiImage = new Swiper('.ai-image_slider',{
        slidesPerView: 1.5,
        spaceBetween: getRem() * .75,
        // slidesOffsetBefore: getRem() * 1,
        // slidesOffsetAfter: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        }
    })

    const stylesMobile = new Swiper('.styles-mobile_slider',{
        slidesPerView: 'auto',
        freeMode: true,
        spaceBetween: getRem() * 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        mousewheel: {
            forceToAxis: true
        }
    })

    const repairCase = new Swiper('.repair-cases_slider',{
        slidesPerView: 4,
        spaceBetween: getRem() * 2.5,
        freeMode: true,
        grabCursor: true,
        grabCursorHTML: '<div class="custom-cursor"></div>',
        mousewheel: {
            forceToAxis: true
        },
    })

    const repairCaseMobile = new Swiper('.repair-cases-mobile_slider',{
        slidesPerView: 1,
        spaceBetween: getRem() * .5,
        navigation: {
            nextEl: ".repair-cases-mobile_slider__next-btn",
            prevEl: ".repair-cases-mobile_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const takeSlider = new Swiper('.swiper-take-slider',{
        slidesPerView: 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".swiper-take-slider__next-btn",
            prevEl: ".swiper-take-slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const tariffsSlider = new Swiper('.swiper-tariffs-slider',{
        slidesPerView: 1,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        navigation: {
            nextEl: ".swiper-tariffs-slider__next-btn",
            prevEl: ".swiper-tariffs-slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const materialMobile = new Swiper('.materials-sections_slider',{
        slidesPerView: 1.5,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        spaceBetween: getRem() * 1,
        navigation: {
            nextEl: ".materials-sections_slider__next-btn",
            prevEl: ".materials-sections_slider__prev-btn",
        },
        mousewheel: {
            forceToAxis: true
        }
    })

    const ganttSteps = new Swiper('.gantt-steps_slider',{
        slidesPerView: 'auto',
        freeMode: true,
        slidesOffsetBefore: getRem() * 1,
        slidesOffsetAfter: getRem() * 1,
        spaceBetween: getRem() * 0.5,
        navigation: {
            nextEl: ".gantt-steps_slider__next-btn",
            prevEl: ".gantt-steps_slider__prev-btn",
        },
        breakpoints: {
            // when window width is >= 320px
            769: {
                slidesPerView: 'auto',
                freeMode: true,
                slidesOffsetBefore: getRem() * 26,
                slidesOffsetAfter: getRem() * 1,
                spaceBetween: getRem() * 2.5,
            },
        },
        mousewheel: {
            forceToAxis: true
        }
    })
}

export function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2)
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { slider } from "./путь/к/файлу/slider.js";

// Активация: slider();