import {z} from "../libraries/zod.js"
import {dialogSuccess} from "./dialog-success.js";
import {findParentWithClass, patchData, sendData, sendFormData} from "./utils.js";
import {resetPhotos, addPhoto, websocketSettings} from "./websocketSettings.js";

function getTextInsideSquareBrackets(text) {
    const startIndex = text.indexOf('['); // Находим индекс первой открывающей скобки
    const endIndex = text.lastIndexOf(']'); // Находим индекс последней закрывающей скобки
    if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) { // Проверяем, что скобки найдены и первая скобка находится перед последней
        return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
    } else {
        return ''; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
    }
}

const schemaFeedback = z.object({
    email: z.string()
        .email({message: "Invalid email address"})
        .optional()
        .or(z.literal('')),
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
    comment: z.string(),
});
const schemaDemo = z.object({
    email: z.string()
        .email({message: "Invalid email address"}),
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),

});
const schemaConsultation = z.object({
    email: z.string()
        .email({message: "Invalid email address"})
        .optional()
        .or(z.literal('')),
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
    comment: z.string(),

});
const schemaJobResponse = z.object({
    email: z.string()
        .email({message: "Invalid email address"})
        .optional()
        .or(z.literal('')),
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
    comment: z.string(),

});
const schemaQuiz = z.object({
    email: z.string()
        .email({message: "Invalid email address"})
        .optional()
        .or(z.literal('')),
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
    comment: z.string(),

});
const schemaConsultationShort = z.object({
    phone: z.string()
        .min(18),

});
const schemaPriceCalc = z.object({
    phone: z.string()
        .min(18),

});
const schemaFooterCallback = z.object({
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
});

const schemaNeuroForm = z.object({
    prompt: z.string()
        .min(1)
        .max(100),
});

const schemaNeuroFeedbackForm = z.object({
    full_name: z.string()
        .min(1),
    phone: z.string()
        .min(18),
});

const schemaNewPass = z.object({
    password: z.string()
        .min(4, {message: "слишком коротко"}),
    repeat_password: z.string()
        .min(4, {message: "слишком коротко"}),
}).refine(
    (data) => data.password === data.repeat_password, {
        message: "Passwords don't match",
        path: ["password", "repeat_password"], // path of error
    });

export function validation() {

    const feedback = document.getElementById('feedback-form');
    if (feedback) {
        formValidate(
            feedback,
            schemaFeedback,
            (body) => {

                feedback.querySelectorAll('input[name="countRoomRadio"]').forEach(input => {
                    if (input.checked) body.count_room = input.id;
                });

                const rangeInput = feedback.querySelector('input[type="range"]');

                if (rangeInput) body.area = Number(rangeInput.value);

                getSelectsValue(feedback, body);
                sendData(body, '/api/feedback/order/')
                    .then(res => {
                        window.location.href = res.success_url;
                    }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const demo = document.getElementById('demo-form');
    if (demo) {
        formValidate(
            demo,
            schemaDemo,
            (body) => {
                sendData(body, '/api/feedback/demo/').then(res => {
                    clearAllForm(demo);
                    dialogSuccess("Успешно!", "Ожидайте звонка от нашего менеджера.");
                }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const consultation = document.getElementById('consultation-form');
    if (consultation) {
        formValidate(
            consultation,
            schemaConsultation,
            (body) => {
                getSelectsValue(consultation, body);
                sendData(body, '/api/feedback/consultation/').then(res => {
                    clearAllForm(consultation);
                    dialogSuccess("Успешно!", "Ожидайте звонка от нашего менеджера.");
                }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const jobResponse = document.getElementById('job-response-form');
    if (jobResponse) {
        formValidate(
            jobResponse,
            schemaJobResponse,
            (body) => {
                const form = new FormData(jobResponse);
                const file = document.getElementById('file-input');
                form.delete('singleSelect');
                if (file.files[0]) {
                    form.append('cv', file.files[0]);
                }
                const selects = jobResponse.querySelectorAll('.__select');
                selects.forEach(select => {
                    const variants = select.querySelectorAll('input[type="radio"]');
                    const selectName = select.getAttribute('data-field');

                    variants.forEach(variant => {
                        const variantId = variant.getAttribute('data-id');
                        if (variantId && variant.checked) {
                            form.append(`${selectName}`, Number(variantId));
                        } else if (variant.checked) {
                            form.append(`${selectName}`, variant.innerText);
                        }
                        ;
                    });
                });

                sendFormData(form, '/api/feedback/vacancy/').then(res => {
                    clearAllForm(jobResponse);
                    dialogSuccess("Успешно!", "Ожидайте звонка от нашего менеджера.");
                }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const quiz = document.getElementById('quiz-form');
    if (quiz) {
        formValidate(
            quiz,
            schemaQuiz,
            (body) => {
                const quizArr = [];

                const quizInfoBlock = document.querySelector('.quiz-section__info-block');
                if (quizInfoBlock) {
                    const quizItems = quizInfoBlock.querySelectorAll('.quiz-section__item');
                    quizItems.forEach(item => {
                        const quizObj = {};

                        quizObj.question = Number(item.getAttribute('data-id'));

                        const answers = item.querySelectorAll('input');
                        const answersArr = [];
                        answers.forEach(answer => {
                            if (answer.checked) {
                                answersArr.push(Number(answer.id));
                            }
                        })

                        quizObj.answer = answersArr;

                        quizArr.push(quizObj);
                    })
                }

                body.quiz = quizArr;

                quiz.querySelectorAll('input[name="countRoomRadio"]').forEach(input => {
                    if (input.checked) body.count_room = input.id;
                });

                getSelectsValue(quiz, body);
                const rangeInput = quiz.querySelector('input[type="range"]');

                if (rangeInput) body.area = Number(rangeInput.value);
                sendData(body, '/api/feedback/quiz/').then(res => {
                    clearAllForm(quiz);
                    // window.location.href = res.success_url;
                }).catch(error => {
                    console.log("error on feedback");
                    const quizList = quizInfoBlock.querySelectorAll('.quiz-section__item__list');
                    let firstNotChechked = false;
                    let firstNotChechkedElem = '';

                    quizList.forEach((list, index) => {
                        let checked = false;
                        list.querySelectorAll('input').forEach(input => {
                            if (input.checked) checked = true;
                        })
                        if (!checked && !firstNotChechked) {
                            firstNotChechked = true;
                            firstNotChechkedElem = list;
                        }
                        if (!checked) {
                            list.classList.add('error');
                        }
                    });

                    if (firstNotChechked) {
                        const htmlEl = document.querySelector('html');
                        htmlEl.style.scrollBehavior = 'smooth';
                        window.scrollTo({top: firstNotChechkedElem, behavior: 'smooth'});
                        setTimeout(() => {
                            htmlEl.style.scrollBehavior = null;
                        }, 300);
                    }

                })
            },
        )
    }

    const consultationShort = document.getElementById('consultation-short');
    if (consultationShort) {
        formValidate(
            consultationShort,
            schemaConsultationShort,
            (body) => {
                sendData(body, '/api/feedback/consultation/').then(res => {
                    clearAllForm(consultationShort);
                    dialogSuccess("Успешно!", "Ожидайте звонка от нашего менеджера.");
                }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const priceCalc = document.getElementById('price-calc-form');
    if (priceCalc) {
        formValidate(
            priceCalc,
            schemaPriceCalc,
            (body) => {
                let radio = '';
                if (window.innerWidth > 769) {
                    radio = priceCalc.querySelectorAll('input[name="priceCalcRadio"]');
                } else {
                    radio = priceCalc.querySelectorAll('input[name="priceCalcRadioMobile"]');
                }
                let checkedId = ''
                radio.forEach(input => {
                    if (input.checked) {
                        checkedId = input.id;
                        body.count_room = input.id;
                    }
                })
                const area = priceCalc.querySelector('.range-input').value;

                const rangeInput = priceCalc.querySelector('input[type="range"]');
                if (rangeInput) body.area = Number(rangeInput.value);

                sendData(body, '/api/feedback/order/').then(res => {
                    window.location.href = res.success_url;
                }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const footerCallback = document.getElementById('footer-callback');
    if (footerCallback) {
        formValidate(
            footerCallback,
            schemaFooterCallback,
            (body) => {
                sendData(body, '/api/feedback/consultation/')
                    .then(res => {
                        clearAllForm(footerCallback);
                        console.log(1)
                        dialogSuccess("Успешно!", "Ожидайте звонка от нашего менеджера.");
                    }).catch(error => {
                    console.log("error on feedback");
                    console.error(error);
                })
            }
        )
    }

    const orderEdit = document.getElementById('order-edit-form');
    if (orderEdit) {
        formValidate(
            orderEdit,
            schemaQuiz,
            (body) => {
                const quizArr = [];

                const quizInfoBlock = document.querySelector('.quiz-section__info-block');
                if (quizInfoBlock) {
                    const quizItems = quizInfoBlock.querySelectorAll('.quiz-section__item');
                    quizItems.forEach(item => {
                        const quizObj = {};

                        quizObj.question = Number(item.getAttribute('data-id'));

                        const answers = item.querySelectorAll('input');
                        const answersArr = [];
                        answers.forEach(answer => {
                            if (answer.checked) {
                                answersArr.push(Number(answer.id));
                            }
                        })

                        quizObj.answer = answersArr;

                        quizArr.push(quizObj);
                    })
                }

                body.quiz = quizArr;

                orderEdit.querySelectorAll('input[name="countRoomRadio"]').forEach(input => {
                    if (input.checked) body.count_room = input.id;
                });

                getSelectsValue(orderEdit, body);
                const rangeInput = orderEdit.querySelector('input[type="range"]');

                if (rangeInput) body.area = Number(rangeInput.value);
                patchData(body, '/api/feedback/order/').then(res => {
                    clearAllForm(orderEdit);
                    window.location.href = res.success_url;
                }).catch(error => {
                    console.log("error on feedback");
                    const quizList = quizInfoBlock.querySelectorAll('.quiz-section__item__list');
                    let firstNotChechked = false;
                    let firstNotChechkedElem = '';

                    quizList.forEach((list, index) => {
                        let checked = false;
                        list.querySelectorAll('input').forEach(input => {
                            if (input.checked) checked = true;
                        })
                        if (!checked && !firstNotChechked) {
                            firstNotChechked = true;
                            firstNotChechkedElem = list;
                        }
                        if (!checked) {
                            list.classList.add('error');
                        }
                    });

                    if (firstNotChechked) {
                        const htmlEl = document.querySelector('html');
                        htmlEl.style.scrollBehavior = 'smooth';
                        window.scrollTo({top: firstNotChechkedElem, behavior: 'smooth'});
                        setTimeout(() => {
                            htmlEl.style.scrollBehavior = null;
                        }, 300);
                    }

                })
            },
        )
    }

    const neuroForm = document.getElementById('neuroForm');
    if (neuroForm) {
        formValidate(
            neuroForm,
            schemaNeuroForm,
            (body) => {
                neuroForm.querySelectorAll('input[name="style"]').forEach(input => {
                    if (input.checked) body.style = input.dataset.value;
                });

                sendData(body, '/api/ai-generator/generate/')
                    .then(res => {
                        neuroForm.elements.button.classList.add('button_primary_light_disabled')
                        resetPhotos()
                        if (res['request_id']) {
                            websocketSettings(res['request_id'])
                        } else {
                            res.urls.forEach(item => {
                                addPhoto(item)
                            })
                        }
                    }).catch(error => {
                    if (error.status == 429) {
                        dialogSuccess("Привышен лимит", "Привышен лимит запросов на генерацию изображений")
                        const modal = document.getElementById('neuroFeedbackModal')
                        modal.setAttribute('data-modal-state', 'open');
                    } else {
                        console.error(error.statusText)
                    }
                })
            }
        )
    }

    const neuroFeedbackForm = document.getElementById('neuroFeedbackForm');
    if (neuroFeedbackForm) {
        formValidate(
            neuroFeedbackForm,
            schemaNeuroFeedbackForm,
            (body) => {

                sendData(body, '/api/feedback/order/')
                    .then(res => {
                        neuroFeedbackForm.reset();
                        const modal = document.getElementById('neuroFeedbackModal');
                        modal.setAttribute('data-modal-state', 'close');
                    }).catch(error => {
                    console.error(error.statusText)
                })
            }
        )
    }
}

function formValidate(form, schema, callBack, formData) {
    form.onsubmit = (e) => {
        e.preventDefault();
    }
    const button = form.elements.button;

    const inputs = Object.keys(getBody(form))
        .map(el =>
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el => Array.from(el).find(elem => elem.closest(`#${form.id}`)));

    inputs.forEach((el) => {
        el.oninput = (evt) => {
            inputs.forEach((e) => {
                if (e.parentNode.classList.contains('field')) {
                    e.parentNode.classList.remove('error');
                } else {
                    const field = findParentWithClass(e, 'field')
                    field.classList.remove('error')
                }
            });

            const body = getBody(evt.target.form);
            validateParse({
                schema: schema,
                body,
            }).then(res => {
                if (button.classList.contains('button_primary_light')) button.classList.remove('button_primary_light_disabled');
                if (button.classList.contains('button_primary_dark')) button.classList.remove('button_primary_dark_disabled');
                button.onclick = () => {
                    const formDataBody = new FormData(form);
                    if (callBack) formData ? callBack(formDataBody) : callBack(body);
                }
            }).catch(error => {
                const parse = JSON.parse(`[${getTextInsideSquareBrackets(error.toString())}]`);
                const nameErrorInput = parse.map(el => el.path[0]);
                const input = inputs.filter(el => nameErrorInput.includes(el.name));
                if (button.classList.contains('button_primary_light')) button.classList.add('button_primary_light_disabled');
                if (button.classList.contains('button_primary_dark')) button.classList.add('button_primary_dark_disabled');
                input.forEach((el) => {
                    if (el.parentNode.classList.contains('field')) {
                        el.parentNode.classList.add('error');
                    } else {
                        const field = findParentWithClass(el, 'field')
                        field.classList.add('error')
                    }
                });
                button.onclick = () => {
                };
            });
        }
    })
}

function clearAllForm(form) {
    const inputs = Object.keys(getBody(form))
        .map(el =>
            document.querySelectorAll(`*[name=${el}]`)
        )
        .map(el => Array.from(el).find(elem => elem.closest(`#${form.id}`)));

    inputs.forEach((el) => {
        if (el.value && el.value !== '' && window.maskInstances) {
            el.value = '';
            window.maskInstances.forEach(maskObj => {
                if (maskObj.element === el) {
                    maskObj.instance.updateValue();
                }
            });
        }
    });
    const button = form.elements.button;
    button.classList.add('button_primary_light_disabled');
}

function getSelectsValue(form, body) {
    const selects = form.querySelectorAll('.__select');

    selects.forEach(select => {
        const variants = select.querySelectorAll('input[type="radio"]');
        const selectName = select.getAttribute('data-field');

        variants.forEach(variant => {
            const variantId = variant.getAttribute('data-id');
            // const label = form.querySelector(`label[for="${variant.id}"]`);
            if (variantId && variant.checked) {
                body[`${selectName}`] = Number(variantId);
            } else if (variant.checked) {
                body[`${selectName}`] = variant.getAttribute('data-name');
            }
        })
    })
}

async function validateParse(validateInfo) {
    try {
        validateInfo.schema.parse(validateInfo.body);
        // console.log("Validation successful");
        if (typeof validateInfo?.callback == 'function') validateInfo?.callback();
        return true;
    } catch (error) {
        if (error instanceof z.ZodError) {
            // console.error("Validation failed:", error.errors);
            throw new Error(JSON.stringify(error.errors));
        } else {
            // console.error("Unknown error", error);
        }
    }
}

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        if (value !== "on") body[name] = value;
    }
    return body;
}

function getQuery(query) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(query);
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { validation } from "./путь/к/файлу/validation.js";

// Активация: validation();