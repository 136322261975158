export function technicalCursor() {
    const technicalScene = document.querySelector('.technical-main-section__image-block');

    if(technicalScene) {
        technicalScene.addEventListener('mouseenter',()=>{
            setTechnicalCursor()
        });
        technicalScene.addEventListener('mouseleave',()=>{
            setDefaultCursor()
        });
    }
    
}

function setTechnicalCursor() {
    const customCursor = document.querySelector('.custom-cursor');
    customCursor.classList.add('custom-cursor_technical');
    customCursor.innerHTML = `
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M64 32C38.4 32 32 10.6667 32 0C32 10.6667 25.6 32 0 32C10.6667 32 32 38.4 32 64C32 38.4 53.3333 32 64 32Z" fill="white" stroke="#DEDFE0"/>
            <path d="M32.8906 24.8915V31.1137H39.1128V32.8915H32.8906V39.1137H31.1128V32.8915H24.8906V31.1137H31.1128V24.8915H32.8906Z" fill="#1B2230"/>
        </svg>
    `;
    customCursor.style.transform = `translate3d(-50%, -50%, 0)`;
}
function setDefaultCursor() {
    const customCursor = document.querySelector('.custom-cursor');
    customCursor.classList.remove('custom-cursor_technical');
    customCursor.innerHTML = '';
}