export function parallax () {
    if(document.querySelectorAll('.parallax-elem').length > 0) {
        document.addEventListener('DOMContentLoaded', ()=>{
            const parallaxLayers = document.querySelectorAll('.parallax-elem');
            parallaxLayers.forEach((layer)=>{
                if(window.innerWidth > 768) {
                    layer.style.transform = 'translateY(-38px)';
                } else {
                    layer.style.transform = 'translateY(-32px)';
                }
            })
            window.addEventListener('scroll', ()=>{
    
                if(parallaxLayers) {
                    parallaxLayers.forEach((layer)=>{
                        const rect = layer.getBoundingClientRect()
                        const scrollTrigger = window.innerHeight;
                        if(rect.top < scrollTrigger && rect.bottom > 0) {
                            let depth = layer.getAttribute('data-depth');
                            let translateY = -(rect.top * depth);
            
                            layer.style.transform = 'translateY(' + translateY + 'px)';
                        }
                    });
                };
    
            }, {passive: true});
        });
    }
}