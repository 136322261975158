import { gsap } from "gsap/all";

import '../styles/main.scss';

import { scroll } from "./libraries/scroll.js";
import { customCursor } from "./modules/customCursor.js";

import { calcHeightOnMobile } from "./modules/calcHeightOnMobile.js";
import { customSlider } from "./modules/base/custom-range-slider.js";
import { accordion } from "./modules/base/accordion.js";
import { select } from "./modules/base/select.js";
import { parallax } from "./modules/base/parallax.js";
import { anchor } from "./modules/base/anchor.js";

import * as pages from "./modules/pages/index.js"
import { header } from "./modules/header.js";
import { inputs } from "./modules/inputs.js";
import { feedBack } from "./modules/feedBack.js";
import { validation } from "./modules/validation.js";
import { slider } from "./modules/slider.js";
import { endlessPagination } from "./modules/endless-pagination.js";
import { menu } from "./modules/menu.js";
import { filters } from "./modules/filters.js";
import { filtersDinamic } from "./modules/filters-dinamic.js";

import { scrollTrigger } from "./modules/animations/scroll-trigger.js";
import { numberStatistics } from "./modules/animations/number-statistics.js";
import { priceCalculator } from "./modules/price-calculator.js";
import { videoControls } from "./modules/video-controls.js";
import { quiz } from "./modules/quiz.js";

import { partnersHover } from "./modules/partners-hover.js";
import { hoverChangePhoto } from "./modules/hover-change-photo.js";
import { hoverChangeDocumentation } from "./modules/hover-change-documentation.js";

import { sliderSpan } from "./modules/slider-span.js";
import { map } from "./modules/map.js";
import { productImage } from "./modules/product-image.js";

import { copyText } from "./modules/copy-text.js";
import { prevLocation } from "./modules/prevLocation.js";

import { preload } from "./modules/preload.js";
import {neuroForm} from "./modules/websocketSettings.js";
import {modals} from "./modules/modals.js";

neuroForm()
scroll()
customCursor()

calcHeightOnMobile()
customSlider()
accordion()
select()
parallax()
anchor()

header()
inputs()
feedBack()
validation()
slider()
endlessPagination()
menu()
filters()
filtersDinamic()

scrollTrigger()
numberStatistics()
priceCalculator()
videoControls()
quiz()

partnersHover()
hoverChangePhoto()
hoverChangeDocumentation()


sliderSpan()
map()
productImage()

copyText()
prevLocation()
modals()

preload()