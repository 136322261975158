export function map() {
    async function initMap() {
        await ymaps3.ready;

        const {YMap, YMapDefaultSchemeLayer, YMapMarker, YMapDefaultFeaturesLayer} = ymaps3;

        let coordString = $('#map').data('coord');
        let coordArray = coordString.split(',').map(Number);

        let center = coordArray.reverse();
        const map = new YMap(
            document.getElementById('map'),
            {
                location: {
                    center: center,
                    zoom: 16
                }
            }
        );


        const layer = new YMapDefaultSchemeLayer({
            customization: [
                {
                    tags: {all: ['water']},
                    elements: 'geometry',
                    stylers: [{color: '#151B26'}]
                },
                {
                    tags: {any: ['entrance']},
                    elements: 'geometry.fill',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {any: ['poi', 'transit_location', 'transit_stop', 'transit_entrance', 'fuel_station', 'hotel', 'food_and_drink', 'shopping', 'commercial_services', 'medical', 'cemetery', 'major_landmark', 'park', 'beach', 'sports_ground']},
                    elements: 'label',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['poi']},
                    elements: 'label.text.outline',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['major_landmark']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'geometry',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'label.text.outline',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['road']},
                    elements: 'label',
                    stylers: [{color: '#FFFFFF'}]
                },
                {
                    tags: {all: ['road_1']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['road_2']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['road_limited']},
                    elements: 'geometry',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {any: ['road_unclassified']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['road_minor']},
                    elements: 'geometry',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['path']},
                    elements: 'geometry',
                    stylers: [{color: '#193A55'}]
                },
                {
                    tags: {all: ['landscape']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['landcover']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['vegetation']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['structure']},
                    elements: 'geometry',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['urban_area']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['residential']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {any: ['urban_area', 'residential', 'industrial']},
                    elements: 'label.text.outline',
                    stylers: [{color: "#FF0000"}]
                },
                {
                    tags: {any: ['industrial']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {any: ['sports_ground']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['district']},
                    elements: 'geometry.fill',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['building']},
                    elements: 'geometry',
                    stylers: [{color: '#ff0000'}]
                },
                {
                    tags: {all: ['fence']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['construction_site']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['park']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['medical']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['cemetery']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['hotel']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['fuel_station']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['beach']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['courtyard']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['yard']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['common_area']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                },
                {
                    tags: {all: ['address']},
                    elements: 'label',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['locality']},
                    elements: 'label',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['district']},
                    elements: 'label',
                    stylers: [{opacity: 0}]
                },
                {
                    tags: {all: ['land']},
                    elements: 'geometry',
                    stylers: [{color: '#151B26'}]
                },

                {
                    tags: {any: ['country', 'region', 'locality', 'district', 'address']},
                    elements: 'geometry',
                    stylers: [{color: '#29446B'}]
                }
            ]
        });

        map.addChild(layer);

        const featuresLayer = new YMapDefaultFeaturesLayer();
        map.addChild(featuresLayer);

        const markerElement = document.createElement('div');
        markerElement.className = 'custom-marker';
        markerElement.style.width = '64px';
        markerElement.style.height = '64px';
        markerElement.style.backgroundImage = 'url(../static/assets/images/icons/map-pin-icon.svg)';
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.backgroundPosition = 'center';
        markerElement.style.borderRadius = '50%';
        markerElement.style.position = 'absolute';
        markerElement.style.left = '50%'; // Примерное позиционирование по центру
        markerElement.style.top = '50%';
        markerElement.style.transform = 'translate(-50%, -50%)'; // Центрируем относительно своих размеров

// Убедитесь, что маркер остаётся на переднем плане:
        markerElement.style.zIndex = '1000'; // Примерное значение, может потребоваться изменение


        const marker = new YMapMarker(
            {
                coordinates: center,
            },
            markerElement
        );

        map.addChild(marker);

    }

    if(document.getElementById('map')) initMap();
}