import { gsap } from "gsap/all";

export function benefitAnimations() {
    const benefitSection = document.querySelectorAll('.benefit-animate-section');
    benefitSection.forEach(section=> {
        const benefit = section.querySelectorAll('.benefit-animate');
        benefit.forEach((el, index) => {
            gsap.fromTo(el, 
                { 
                    x: 50, 
                    opacity: 0 
                }, 
                { 
                    x: 0, 
                    opacity: 1, 
                    duration: 0.6, 
                    delay: index * 0.3,
                    scrollTrigger: {
                        trigger: el,
                        toggleActions: "play none none none",
                        once: true
                    }
                }
            );
        });

        const benefitMobile = section.querySelectorAll('.benefit-animate-mobile');

        benefitMobile.forEach((el, index) => {
            gsap.fromTo(el, 
                { 
                    x: 50, 
                    opacity: 0 
                }, 
                { 
                    x: 0, 
                    opacity: 1, 
                    duration: 0.6, 
                    delay: index * 0.3,
                    scrollTrigger: {
                        trigger: el,
                        toggleActions: "play none none none",
                        once: true
                    }
                }
            );
        });
    })
}