export function addCardVacanies(data, section) {
    const fragment = document.createDocumentFragment();

    data.forEach(elem=>{
        const link = document.createElement('a');
        const hr = document.createElement('hr');

        link.href = `/job-response/?id=${elem.id}`;
        link.classList.add('vacancy-card');

        let responsibilities = elem.responsibilities.map(responsibility => `<li class="brand-star">${responsibility}</li>`).join('');
        let requirements = elem.requirements.map(requirement => `<li class="brand-star">${requirement}</li>`).join('');
        let categories = elem.categories.map(category => `<span>${category}</span>`).join('');

        let salary = elem.base_salary == 0 ? 'По итогам собеседования' : `${elem.base_salary}₽`;

        link.innerHTML = `
            <div class="vacancy-card__title-block">
                <div class="vacancy-card__title-block__title">
                    ${elem.name}
                </div>
                <div class="vacancy-card__title-block__info">
                    <span>
                        ${salary}
                    </span>
                    <span>
                        ${categories}
                    </span>
                </div>
            </div>
            <div class="vacancy-card__info-block">
                <div class="vacancy-card__info-block__title">
                    Обязанности
                </div>
                <ul>
                    ${responsibilities}
                </ul>
            </div>
            <div class="vacancy-card__info-block">
                <div class="vacancy-card__info-block__title">
                    Требования
                </div>
                <ul>
                    ${requirements}
                </ul>
                <div class="button button_size_small button_primary_light">
                    Откликнуться
                </div>
            </div>
        `;


    fragment.appendChild(link);
    fragment.appendChild(hr);
    })

    section.appendChild(fragment);
}