export function customCursor() {
    const cursor = document.querySelector('.custom-cursor');

    if(cursor) {
        const positionCursor = (e)=> {
            const mouseY = e.clientY;
            const mouseX = e.clientX;
            
            cursor.style.top = `${mouseY}px`;
            cursor.style.left = `${mouseX}px`;
        }
    
        const smallCursor = ()=> {
            if(cursor.innerHTML == '') cursor.style.transform = `scale(0.7) translate3d(-50%, -50%, 0)`;
        }
        const normalCursor = ()=> {
            cursor.style.transform = `translate3d(-50%, -50%, 0)`;
        }
    
        if(window.innerWidth > 769) {
            window.addEventListener('pointermove', positionCursor);
            window.addEventListener('mousedown', smallCursor);
            window.addEventListener('mouseup', normalCursor);
            window.addEventListener('dragover', positionCursor);
            window.addEventListener('touchmove', positionCursor);
            window.addEventListener('mousemove', positionCursor);
        }
    }
}