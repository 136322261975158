import { gsap, ScrollToPlugin, ScrollTrigger } from "gsap/all";

import { benefitAnimations } from "./benefit-animations.js"
import { designCase } from "./design-case.js"
import { offerBasic } from "./offer-basic.js"
import { quizStart } from "./quiz-start.js"
import { repairCase } from "./repair-case.js"
import { videoAboutDesign } from "./video-about-design.js"
import { videoContent } from "./video-content.js"
import { mainHero } from "./main-hero.js"
import { footerAnimation } from "./footer-animation.js"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin) 

export function scrollTrigger() {
    benefitAnimations()
    repairCase()
    designCase()
    offerBasic()
    videoContent()
    videoAboutDesign()
    quizStart()
    mainHero()
    footerAnimation()
}