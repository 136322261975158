import {gsap, ScrollTrigger} from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

export function footerAnimation() { 
    const main = document.querySelector('main'); 
    const fakeMain = document.querySelector('.fake_main');

    const footerSection = document.querySelector('footer');

    if(footerSection) {
        if (window.innerWidth > 768 && window.innerWidth < 1923 && !footerSection.classList.contains('__no_animate')) {
            let firstTouch = true;
    
            const footerContainer = document.querySelector('.footer-section__container');
            if (footerSection) {
                const footerRect = footerSection.getBoundingClientRect();
                if (footerRect.height < window.innerHeight) footerContainer.style.height = 'calc(100dvh - 8rem)';
            }
    
            window.addEventListener('scroll', () => {
                const footerRect = footerSection.getBoundingClientRect();
                if (window.innerHeight / 4 * 3 > footerRect.top && firstTouch) {
                    firstTouch = false;
    
                    const fake = document.createElement('section');
                    const rect = main.getBoundingClientRect();
    
                    fake.classList.add('fake-section');
                    fake.style.height = `${rect.height}px`;
                    fake.style.content = '';
    
                    fakeMain.appendChild(fake);
    
                    main.style.position = 'fixed';
                    main.style.width = '100vw';
                    main.style.bottom = footerSection.getBoundingClientRect().height / 4 + 'px';
                    main.style.left = '50%';
                    main.style.transform = 'translateX(-50%)';
                } else if (window.innerHeight / 4 * 3 < footerRect.top && !firstTouch) {
                    firstTouch = true;
    
                    const fakeSection = document.querySelector('.fake-section');
                    if (fakeSection) {
                        fakeSection.parentNode.removeChild(fakeSection);
                    }
                    main.style.position = null;
                    main.style.width = null;
                    main.style.bottom = null;
                    main.style.left = null
                    main.style.transform = null;
                }
            })
        }
    }
}