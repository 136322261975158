import { setCurrentProducts } from './setCurrentProducts.js';
import { technicalCursor } from './technical-cursor.js';
import { clickOnScene } from './clickOnScene.js';
import { pagination } from './pagintation.js';
import { saveTechnical } from './saveTechnical.js';
import { searcher } from './searcher.js';

function init() {
    setCurrentProducts();
    
    technicalCursor();
    
    clickOnScene();

    pagination();
    
    saveTechnical();
    searcher();
}

export {
    init   
}