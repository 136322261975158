import { gsap, ScrollTrigger } from "gsap/all";

export function mainHero() {
    const mainHeroSection = document.querySelector('.main-hero-section');
    const numbersSection = document.querySelector('.numbersSection');
    const fakeMainHero = document.querySelector('.main-hero-section_fake');
    const designSection = document.querySelector('.designSection');
    const helper = document.querySelector('.numbers-about-section__helper');
    const header = document.querySelector('header');

    const swapHeader = document.querySelector('.swap-header')

    if(swapHeader) {
        let prevScrollPos = window.pageYOffset;
        const windowHeight = document.documentElement.clientHeight
        window.onscroll = function() {
            const currentScrollPos = window.pageYOffset;

            if (prevScrollPos > currentScrollPos) {
                const rect = swapHeader.getBoundingClientRect();
                if(-rect.y < windowHeight) {
                    header.classList.remove('white-version');
                } else {
                    header.classList.add('white-version');
                }
            } else {
                const rect = swapHeader.getBoundingClientRect();
                if(-rect.y < windowHeight) {
                    header.classList.remove('white-version');
                } else {
                    header.classList.add('white-version');
                }
            }

            prevScrollPos = currentScrollPos;
        }
    }

    if(mainHeroSection) {

        let isPageLoaded = false;

        window.addEventListener('load', () => {
            isPageLoaded = true;
        });


        ScrollTrigger.create({
            trigger: mainHeroSection,
            start: 'bottom bottom',
            onEnter: () => {
                if(isPageLoaded) {
                    gsap.to(window, { duration: 2, scrollTo: numbersSection });
                    mainHeroSection.style.position = "fixed";
                    mainHeroSection.style.bottom = "0";
                    fakeMainHero.style.display = 'flex';
                    numbersSection.style.position = 'relative';
                    numbersSection.style.background = '#F4F4F4';
                    designSection.style.position = 'relative';
                    designSection.style.background = '#F4F4F4';
                }
            },
            onLeave: () => {
                if(isPageLoaded) {
                    mainHeroSection.style.position = "static"
                    fakeMainHero.style.display = 'none';
                }
            },
            // markers: true,
        });


        helper.addEventListener('click',() => {
            gsap.to(window, { duration: 2, scrollTo: numbersSection });
        });
    }
}