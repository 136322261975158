export function hoverChangePhoto() {
    const hoverSections = document.querySelectorAll('.hover-change-section');

    if (hoverSections.length > 0) {
        createHoverChangePhotoEffect(
            '.hover-change-section', 
            '.hover-change-section__image',
            '.hover-change-section__image img',
            '.hover-change-section__hover-card',
            '.hover-change-section__hover-btn'
        );
    }
}

export function createHoverChangePhotoEffect(
    hoverSectionSelector,
    hoverImageBlockSelector,
    hoverImageSelector,
    hoverCardSelector,
    hoverButtonSelector
  ) {
    const hoverSections = document.querySelectorAll(hoverSectionSelector);
  
    if (hoverSections.length > 0) {
        hoverSections.forEach(section => {
            const hoverImageBlock = section.querySelector(hoverImageBlockSelector);
            const hoverImages = hoverImageBlock.querySelectorAll(hoverImageSelector);
    
            const hoverCards = section.querySelectorAll(hoverCardSelector);
    
            let hoverButtonHeight = '';
            let switchCase = false;
  
            hoverCards.forEach(card => {
                card.addEventListener('mouseover', () => {
                    const imgIndex = card.getAttribute('data-image-index');
        
                    hoverImages.forEach(hoverImage => {
                    const hoverImageData = hoverImage.getAttribute('data-image');
                    if (hoverImageData === imgIndex) {
                        hoverCards.forEach(hover => {
                        const hoverButton = hover.querySelector(hoverButtonSelector);
                        if (hover === card) {
                            hover.style.opacity = 1;
                            if (hoverButton && switchCase) {
                                hoverButton.style.opacity = 1;
                                hoverButton.style.height = hoverButtonHeight;
                            } else if (hoverButton && !switchCase) {
                                hoverButton.style.opacity = 1;
                                switchCase = true;
                                hoverButton.style.height = hoverButton.scrollHeight + "px";
                                hoverButtonHeight = hoverButton.scrollHeight + "px";
                            }
                        }
                        });

                        hoverImage.style.opacity = '1';
                        setTimeout(() => {
                            hoverImage.style.display = 'block';
                        }, 300);
                    } else {
                        hoverImage.style.opacity = '0';
                        hoverCards.forEach(hover => {
                            const hoverButton = hover.querySelector(hoverButtonSelector);
                            if (hover !== card) {
                                hover.style.opacity = .4;
                                if (hoverButton) {
                                hoverButton.style.opacity = null;
                                hoverButton.style.height = null;
                                }
                            }
                        });
                        setTimeout(() => {
                        hoverImage.style.display = 'none';
                        }, 300);
                    }
                    });
                });
            });
        });
    }
  }