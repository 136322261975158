import { getData } from "./utils.js";

export function sliderSpan() {
    $('.ba-slider').beforeAfter();
    const sliders = document.querySelectorAll('.ba-slider');

    sliders.forEach(slider=>{
        const resize = slider.querySelector('.resize');

        let beforeArray = [];
        let afterArray = [];
        const sliderId = slider.getAttribute('data-id');
        getData(`/api/cases/${sliderId}/get-before-after-photos/`)
            .then(data=>{
                beforeArray = data.before_photo;
                afterArray = data.after_photo;

                slider.querySelector('.second-image').src = afterArray[0]
                slider.querySelector('.first-image').src = beforeArray[0]

                trackWidthChanges(resize, slider, beforeArray, afterArray);
            });
    });
}

let beforeAfter = true;

function trackWidthChanges(element, slider, beforeArray, afterArray) {
    let imagesIndex = 1;
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'style') {
                const width = element.style.width;
                if(parseInt(width) <= 5 && !beforeAfter) {
                    changeAfterImage(slider, afterArray, imagesIndex);
                    beforeAfter = true;

                    imagesIndex += 1;
                    if(imagesIndex == afterArray.length) imagesIndex = 0;
                }
                if(parseInt(width) >= 95 && beforeAfter) {
                    changeBeforeImage(slider, beforeArray, imagesIndex);
                    beforeAfter = false;
                }
            }
        });
    });

    observer.observe(element, {
        attributes: true,
        attributeFilter: ['style']
    });
};


function changeAfterImage(slider, afterArray, imagesIndex) {
    const secondImage = slider.querySelector('.second-image');
    afterArray.forEach((image, i)=>{
        if(i == imagesIndex) {
            secondImage.src = image;
        };
    });
};

function changeBeforeImage(slider, beforeArray, imagesIndex) {
    const firstImage = slider.querySelector('.first-image');
    beforeArray.forEach((image, i)=>{
        if(i == imagesIndex) {
            firstImage.src = image;
        };
    });
};

