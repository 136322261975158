export function accordion() {
    const accordionItems = document.querySelectorAll('.accordion-item');
    
    if(accordionItems.length > 0) {
        accordionItems.forEach(item => {
            item.addEventListener('click', () => {
                closeOtherAccordion(item);
                const bodyElem = item.querySelector('.accordion-item__body');
                const headerElem = item.querySelector('.accordion-item__header');
                if (headerElem) headerElem.classList.toggle('show');
                if (bodyElem) {
                    if (bodyElem.classList.contains('show')) {
                        bodyElem.style.maxHeight = null;
                    } else {
                        bodyElem.style.maxHeight = bodyElem.scrollHeight * 2 + "px";
                    }
                    bodyElem.classList.toggle('show');
                    item.classList.toggle('show');
                }
            });
        });
    }
};


function closeOtherAccordion(target) {
    document.querySelectorAll('.accordion-item').forEach(el=> {
        const bodyElem = el.querySelector('.accordion-item__body');
        const headerElem = el.querySelector('.accordion-item__header');
        if(el !== target) {
            if (bodyElem) {
                el.classList.remove('show');
                bodyElem.classList.remove('show');
                bodyElem.style.maxHeight = null;
            }
            if (headerElem) headerElem.classList.remove('show');
        }
    })
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { accordion } from "./путь/к/файлу/accordion.js";

// Активация: accordion();