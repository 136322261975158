import { clearFromFirst } from "./clickOnScene.js";
import { appendNewCard, removeCard, setCardsState, showContextualMenu } from "./utils.js";

export function clickOnCard(card) {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');

    if(scene) {
        const addCardBtn = card.querySelector('.product-add-card__btn');
        const id = card.getAttribute('data-id');

        addCardBtn.addEventListener('click',()=>{
            const sceneFirst = checkForScenarioCard(scene);

            if(addCardBtn.classList.contains('active')) {
                removeCard(scene, list, id);
                setCardsState(scene, list);
                list.setAttribute('data-state', 'false');

                return;
            }

            if(sceneFirst) {
                const productCardOi = scene.querySelector('.product-card-oi');

                const left = parseInt(productCardOi.style.left); 
                const top = parseInt(productCardOi.style.top);

                showContextualMenu(list, id);
                appendNewCard(scene, list, card, left, top);
                clearFromFirst(scene);
                setCardsState(scene, list);

                scene.setAttribute('data-state', 'false');
            } else {
                const productCards = list.querySelectorAll('.product-add-card');
                productCards.forEach(card=> card.querySelector('.product-add-card__btn').classList.remove('active'));
                list.setAttribute('data-state', 'active');
                addCardBtn.classList.add('active');

                
            };
        });
    }
}

function checkForScenarioCard(scene) {
    let sceneFirst = true;
    
    if(scene.getAttribute('data-state') == 'false') sceneFirst = false;

    return sceneFirst;
}