export function saveTechnical() {
    const technicalScene = document.querySelector('.technical-main-section__image-block');
    if(technicalScene) {
        const saveBtn = document.querySelector('.saveTechnicalBtn');
        saveBtn.addEventListener('click',()=>{
            const dataJSON = {};
            const dataAllProducts = [];

            const products = technicalScene.querySelectorAll('.product-card-added');
            products.forEach(product=>{
                const productObj = {};
                productObj.product = product.getAttribute('data-id');

                const coordinateObj = {};
                coordinateObj.top = parseInt(product.style.top);
                coordinateObj.left = parseInt(product.style.left);
                productObj.coordinate = coordinateObj;

                dataAllProducts.push(productObj);                
            });

            const photoID = parseInt(technicalScene.id);

            dataJSON.photo = photoID;
            dataJSON.products = dataAllProducts;

            sendData(dataJSON, '/api/ready-solution/add/')
                .then(res=>{
                    window.location.reload();
                })
        });
    }
}
async function sendData(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(data),
    });
    if(response.ok) return response;
};