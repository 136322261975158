import { 
    decorationsAnimation 
} from "./utils.js";

import { gsap, ScrollToPlugin, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

export function designCase() {
    const dialog = document.querySelector('.dialog');
    const designCards = document.querySelectorAll('.design-case-section');
    if (designCards.length > 1) {
        window.addEventListener('scroll',() => {
            let prevScrollPos = window.pageYOffset
            const currentScrollPos = window.pageYOffset;
            
            designCards.forEach((el, i) => {
                const infoBlock = el.querySelector('.design-case-section__info-block');
                const button = el.querySelector('.button_size_basic');
                const number = el.querySelector('.design-case-section__info-block__number');

                const rect = el.getBoundingClientRect();
                const scrollTrigger = window.innerHeight;

                function fixedState() {
                    if(number) number.style.position = 'fixed'; 

                    if(button) button.style.position = 'fixed';

                    if(infoBlock) {
                        infoBlock.style.position = 'fixed';
                        infoBlock.style.transform = 'translate(-50%, -50%)';
                        infoBlock.style.top = `calc(50% + ${(scrollTrigger - rect.height) / 2}px)`;
                    }
                    
                    if(window.innerWidth > 1920) {
                        if(number) number.style.left = '6rem';
                        if(infoBlock) infoBlock.style.maxWidth = 'calc(1920px - 12rem)';
                    } else if(window.innerWidth > 768) {
                        if(number) number.style.left = '6rem';
                        if(infoBlock) infoBlock.style.maxWidth = 'calc(100vw - 12rem)';
                    } else {
                        if(number) number.style.left = '1rem';
                        if(infoBlock) infoBlock.style.maxWidth = 'calc(100vw - 2rem)';
                    }
                }
                function defaultState() {
                    if(number) {
                        number.style.position = 'absolute';
                        number.style.left = '0';
                    }

                    if(button) button.style.position = 'absolute';

                    if(infoBlock) {
                        infoBlock.style.position = 'static';
                        infoBlock.style.transform = 'translate(0, 0)';
                        infoBlock.style.maxWidth = 'none';
                    }
                }



                if (prevScrollPos > currentScrollPos) {
                } else {
                    if(i == 0) {
                        if(rect.top < scrollTrigger - rect.height) {
                            fixedState()
                        } else {
                            defaultState()
                        };
                    } else if(rect.top < scrollTrigger && rect.bottom > 0) {
                        fixedState()
                    } else {
                        defaultState()
                    }
                }


                
                const scrollableEl = el.querySelectorAll('.__scrollable__elem');

                if (rect.top < scrollTrigger && rect.bottom > 0) {
                    scrollableEl.forEach(elem=>{
                        const elRect = elem.getBoundingClientRect();
                        const scrolled = elRect.bottom - rect.bottom;
                        elem.style.clipPath = `inset(0 0 ${scrolled}px 0)`;
                        if(i !== 0) {
                            const scrolledTop = elRect.bottom - rect.top;
                            elem.style.clipPath = `inset(${elRect.height - scrolledTop}px 0 ${scrolled}px 0)`;
                        }
                    });
                    
                } else {
                    scrollableEl.forEach(el=>{
                        el.style.clipPath = 'inset(100% 0 0 0)';
                    });
                }
            });

            prevScrollPos = currentScrollPos;
        });
        designCards.forEach(section=>{
            ScrollTrigger.create({
                trigger: section,
                start: 'top bottom',
                onEnter: () => {
                    decorationsAnimation(section);
                },
            });
        });
    }
}