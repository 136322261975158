export function setCurrentProducts() {
    const scene = document.querySelector('.technical-main-section__image-block');
    const productsActive = document.querySelectorAll('.product-card-added');
    
    if(scene && productsActive.length > 0) {
        productsActive.forEach(product=>{
            const X_PERCENT = parseInt(product.style.left);
            const Y_PERCENT = parseInt(product.style.top);

            let direction = '';
            Y_PERCENT > 50 ? direction = direction + 'bottom' : direction = direction + 'top';
            X_PERCENT > 50 ? direction = direction + 'right' : direction = direction + 'left';

            product.classList.add(direction);
        })
    }
}