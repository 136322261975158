export function anchor() {
    const anchorEl = document.querySelectorAll('.anchor__elem');
    const htmlEl = document.querySelector('html');

    if(anchorEl.length > 0) {
        anchorEl.forEach((el) => {
            const scrollAttr = el.getAttribute('data-scroll');
            const hiddenElement = document.getElementById(scrollAttr);
    
            el.addEventListener('click', (event) => {
                event.preventDefault();
    
                if (hiddenElement) {
                    const marginTop = parseInt(window.getComputedStyle(hiddenElement).marginTop);
                    const targetPosition = hiddenElement.getBoundingClientRect().top + window.scrollY - marginTop - 100;
    
                    htmlEl.style.scrollBehavior = 'smooth';
    
                    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    
                    setTimeout(() => {
                        htmlEl.style.scrollBehavior = null;
                    }, 300);
                }
            });
        });
    }
}



// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { anchor } from "./путь/к/файлу/anchor.js";

// Активация: anchor();