import { clickOnCard } from "./clickOnCard.js";
import { dragNdrop } from "./dragNdrop.js";

export function getPercentsOfClick(event, scene) {
    const sceneSizes = scene.getBoundingClientRect();
    const xPercent = ((event.clientX - sceneSizes.left) / sceneSizes.width) * 100;
    const yPercent = ((event.clientY - sceneSizes.top) / sceneSizes.height) * 100;

    return { xPercent, yPercent };
}
export function getClickPositionDirection(event, scene) {
    const { xPercent, yPercent } = getPercentsOfClick(event, scene);
    const X_PERCENT = xPercent;
    const Y_PERCENT = yPercent;

    let direction = '';
    X_PERCENT > 50 ? direction = direction + 'right' : direction = direction + 'left';
    Y_PERCENT > 50 ? direction = direction + 'bottom' : direction = direction + 'top';

    return direction;
}
export function appendNewCard(scene, list, card, left, top) {

    const id = card.getAttribute('data-id');
    const title = card.querySelector('.product-add-card__text-block__title').innerText;
    const price = card.querySelector('.product-add-card__text-block__title_sub').innerText;
    const image = card.querySelector('img').src;

    let direction = '';
    left > 50 ? direction = direction + 'right' : direction = direction + 'left';
    top > 50 ? direction = direction + 'bottom' : direction = direction + 'top';

    const newCard = document.createElement('div');
    newCard.classList.add('product-card-added');
    newCard.classList.add(`${direction}`);
    
    newCard.style.left = `${left}%`;
    newCard.style.top = `${top}%`;
    newCard.setAttribute('data-id', `${id}`);

    newCard.innerHTML = `
        <div class="product-card-added__info-block">
            <div class="product-card-added__info-block__image-block">
                <img src="${image}">
            </div>
            <div class="product-card-added__info-block__text-block">
                <div class="product-card-added__info-block__text-block__title">
                    ${title.trim()}
                </div>
                <div class="product-card-added__info-block__text-block__title_sub">
                    ${price.trim()}
                </div>
            </div>
        </div>

        <div class="product-card-added__btn">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36 18C21.6 18 18 6 18 0C18 6 14.4 18 0 18C6 18 18 21.6 18 36C18 21.6 30 18 36 18Z" fill="white"/>
            </svg>
        </div>
    `;
    scene.appendChild(newCard);
}
export function removeCard(scene, list, id) {
    const cardsInScene = scene.querySelectorAll('.product-card-added');
    const cardsInList = list.querySelectorAll('.product-add-card');

    cardsInScene.forEach(card=>{
        const cardId = card.getAttribute('data-id');
        if(cardId == id) card.parentElement.removeChild(card);
    });

    cardsInList.forEach(card=>{
        const btn = card.querySelector('.product-add-card__btn');
        const cardId = card.getAttribute('data-id');
        if(cardId == id) btn.classList.remove('active');
    });
}
export function setCardsState(scene, list) {
    const cardsInScene = scene.querySelectorAll('.product-card-added');
    const cardsInList = list.querySelectorAll('.product-add-card');

    setTimeout(() => {
        let activeIds = [];
        cardsInScene.forEach(card=>{
            const id = card.getAttribute('data-id');
            activeIds.push(id);
        });

        cardsInList.forEach(card=>{
            const btn = card.querySelector('.product-add-card__btn');
            const id = card.getAttribute('data-id');
            if(activeIds.includes(id)) btn.classList.add('active');
        });
    }, 50);
}
export function showContextualMenu(list, id) {
    const cardsInList = list.querySelectorAll('.product-add-card');

    cardsInList.forEach(card=>{
        const contextualMenu = document.querySelector('.product-add-card__context-menu');
        const cardId = card.getAttribute('data-id');
        if(cardId == id) {
            const cardRect = card.getBoundingClientRect();
            contextualMenu.style.display = 'flex';
            contextualMenu.style.top = cardRect.top + cardRect.height + 'px';
            setTimeout(() => {
                contextualMenu.style.opacity = 1;
                contextualMenu.style.scale = 1;
            }, 10);
            setTimeout(() => {
                contextualMenu.style.opacity = null;
                contextualMenu.style.scale = null;
            }, 1500);
            setTimeout(() => {
                contextualMenu.querySelector('.product-add-card__context-menu__title').innerText = 'Товар добавлен!';
                contextualMenu.style.display = null;
            }, 1800);
        };
    });
}
export function addCardInList(res, list) {
    const fragment = document.createDocumentFragment();
    res.forEach(elem=>{
        const div = document.createElement('div');

        const photo = elem.photo ? elem.photo : '/static/assets/images/stub.png';

        div.classList.add('product-add-card');
        div.setAttribute('data-id', elem.id);

        div.innerHTML = `
            <div class="product-add-card__info-block">
                <div class="product-add-card__image">
                    <img src="${photo}">
                </div>
                <div class="product-add-card__text-block">
                    <div class="product-add-card__text-block__title">
                        ${elem.name}
                    </div>
                    <div class="product-add-card__text-block__title_sub">
                        от ${elem.price} ₽
                    </div>
                </div>
            </div>
            <button class="icon-button icon-button_size_small icon-button_terteriary product-add-card__btn">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 4V11H20V13H13V20H11V13H4V11H11V4H13Z" fill="#1B2230"/>
                </svg>
            </button>
        `;
        dragNdrop(div);
        clickOnCard(div);

    fragment.appendChild(div);
    });

    list.appendChild(fragment);
}