export function productImage() {
    const productDots = document.querySelectorAll('.product-image-section__dot');

    if(productDots.length > 0) {
        productDots.forEach( productDot => {
            const productCards = document.querySelectorAll('.product-card');
            const dotId = productDot.getAttribute('data-id');
    
            productDot.addEventListener('mouseover', function() {
                productCards.forEach(card => {
                    const cardId = card.getAttribute('data-id');
                    if (cardId === dotId) {
                        card.style.opacity = '1';
                        productDot.classList.add('active');
                    } 
                    else {
                        card.style.opacity = '.4';
                    }
                });
            });
    
            productDot.addEventListener('mouseleave', function() {
                productCards.forEach(card => {
                    card.style.opacity = null;
                    productDot.classList.remove('active');
                });
            })
        });
    }
}