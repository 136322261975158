export function menu() {
    document.addEventListener("DOMContentLoaded",()=>{
        const menuBtn = document.querySelector(".menuBtn");
        const menu = document.querySelector(".menu");
        const header = document.querySelector('header');

        let switchCase = false;

        const toggleMenu = () => {
            const originalHasWhiteVersion = header.classList.contains('white-version');

            const isMenuActive = menu.classList.contains("active");

            if(isMenuActive) {
                menu.classList.remove("active");
                document.documentElement.style.overflow = null;
                menuBtn.innerText = 'Меню';
                if(switchCase) {
                    switchCase = false;
                    header.classList.remove('white-version');
                }
            } else {
                menu.classList.add("active");
                document.documentElement.style.overflow = "hidden";
                menuBtn.innerText = 'Закрыть';
                if(!originalHasWhiteVersion) {
                    switchCase = true;
                    header.classList.add('white-version');
                }
            }
        };

        if (menuBtn) menuBtn.addEventListener("click", toggleMenu);

        let nav = '';
        if(menu) nav = menu.querySelector('.menu__nav');

        if(nav) {
            const links = nav.querySelectorAll('a');
            links.forEach(link=>{
                link.addEventListener('mouseover',(e)=>{
                    links.forEach(el=>{
                        if(e.target !== el) {
                            if(innerWidth > 768) {
                                el.classList.add('link_light_primary_disabled');
                            }
                        } else {
                            el.classList.remove('link_light_primary_disabled');
                        }
                    })
                });
                link.addEventListener('mouseleave',()=>{
                    links.forEach(el=>{
                        el.classList.remove('link_light_primary_disabled');
                    })
                });
            });
        };
    });
}
