import { setNewPage } from "../../endless-pagination.js";
import { getRem } from "../../slider.js";
import { debounce, getData } from "../../utils.js";
import { addCardInList, setCardsState } from "./utils.js";

export function pagination() {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');
    if (list) {
        const reloadedPageHref = new URL(window.location.href);
        reloadedPageHref.searchParams.set('page', 1);
        history.replaceState({}, '', reloadedPageHref.search.toString());
        getData(`/api/products/`)
            .then(res=>{
                addCardInList(res.results, list);

                setCardsState(scene, list);
            });

        list.addEventListener('scroll', debouncedScrollPagination);
    }
}
const debouncedScrollPagination = debounce(onScrollPagination, 300)

function onScrollPagination() {
    const scene = document.querySelector('.technical-main-section__image-block');
    const list = document.querySelector('.technical-main-section__info-block__products-list');
    const productLast = list.querySelector('.product-add-card:last-of-type');
    const productRect = productLast.getBoundingClientRect();
    const listRect = list.parentNode.getBoundingClientRect();

    const dataNext = list.getAttribute('data-next');

    if(productRect.bottom < listRect.bottom + getRem() * 8 && dataNext == 'true') {

        setNewPage();

        const href = new URL(window.location.href);
        let pageValue = href.searchParams.get('page');
        let nameValue = href.searchParams.get('name') ? `&name=${href.searchParams.get('name')}` : '';
        getData(`/api/products/?page=${Number(pageValue)}${nameValue}`)
            .then(res=>{
                if(res.next == null) list.setAttribute('data-next', false);
                addCardInList(res.results, list);
                setCardsState(scene, list);
            });
    }
}